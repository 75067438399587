import { useEffect, useState } from "react";
import { axiosMatsend } from "common/api/axios/matsend_axios";
import ChannelStartList from "./ChannelStartList";

const MatsendStart = () => {
  const [msgAuthRequiredMatsend, setMsgAuthRequiredMatsend] = useState<any>(
    "matsend not accessed"
  );

  useEffect(() => {
    axiosMatsend
      .get("auth/authrequired")
      .then((res) => {
        console.log("matsend res authrequired", res);
        setMsgAuthRequiredMatsend(res.statusText);
      })
      .catch((err) => {
        console.log("matsend res authrequired err", err.response.status);
      });
  }, []);

  return (
    <div>
      {/* <div>START</div>
      <div>Matsend: {msgAuthRequiredMatsend}</div> */}
      <div>
        <ChannelStartList />
      </div>
    </div>
  );
};

export default MatsendStart;
