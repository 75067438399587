import { axiosMatsend } from "../axios/matsend_axios";

export const testContentGet = async (): Promise<string> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.get("test-content");
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
