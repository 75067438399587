import styled from "styled-components";

const SelectedElement = styled.div<{ $selected?: boolean; $cursor?: string }>`
  border-left: ${(props) => (props.$selected ? "0.5rem solid #eee" : "0rem")};
  border-bottom: ${(props) => (props.$selected ? "0.5rem solid #eee" : "0rem")};
  // border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
`;

export default SelectedElement;
