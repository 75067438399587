import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useChannelAdd } from "hooks/matsend/channel/useChannelAdd";
import { QUERY_KEYS } from "constant/react_query";
import ChannelForm from "components/common/channel/ChannelForm/ChannelForm";
import { useNavigate } from "react-router-dom";
import { channelFormDefaultValuesGet } from "common/channel/channel_form_common";

const ChannelNew = () => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: channelFormDefaultValuesGet(),
  });
  const navigate = useNavigate();
  const { channelAddMutate } = useChannelAdd({
    invalidateKeys: [QUERY_KEYS.MATSEND_CHANNEL_SEARCH],
    successHandle: (id: any) => {
      console.log("add success", id);
      navigate(`/channel/${id}`);
    },
  });

  const parentIdRef = useRef<string>("");

  const onSubmit = async (data: any) => {
    const { slug } = data;

    channelAddMutate({
      slug,
      parentChannelId: parentIdRef.current,
    });
    toast.success("New channel added");
  };

  return (
    <>
      <div>Channel Add</div>
      <DivChannel>
        <ChannelForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
          parentIdDefault=""
          parentIdRef={parentIdRef}
        />
      </DivChannel>
    </>
  );
};

const DivChannel = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ChannelNew;
