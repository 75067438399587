import React from "react";
import { NavLink } from "react-router-dom";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import { useIsAdmin } from "hooks/auth/auth_current";
import { MainNavElement } from "type/navigation/main_navigation.type";
import { navArraysGet } from "common/nav/nav_common";
import {
  GENERAL_NAV_ELEMENTS,
  LIST_MAIN_NAV_ELEMENTS,
  NEW_ITEM_NAV_ELEMENTS,
} from "../MainNavigation.constant";
import NavigationGroup from "../NavigationGroup";
import LogoutNav from "components/navigation/LogoutNav";

const LogoutNavDiv = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
`;

type MainNavigationDrawerProps = {
  showMenu: boolean;
};

const MainNavigationDrawer = ({ showMenu }: MainNavigationDrawerProps) => {
  const isAdmin: boolean = useIsAdmin();

  const [
    newItemNavElements,
    listNavElements,
    generalNavElements,
  ]: MainNavElement[][] = navArraysGet(
    [NEW_ITEM_NAV_ELEMENTS, LIST_MAIN_NAV_ELEMENTS, GENERAL_NAV_ELEMENTS],
    isAdmin
  );

  return showMenu ? (
    <div>
      <Toolbar />
      <Divider />
      {newItemNavElements.length > 0 && (
        <>
          <List>
            <ListItem key={"home"} disablePadding>
              <NavLink to="/">
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Start" />
                </ListItemButton>
              </NavLink>
            </ListItem>
            <Divider />
            {newItemNavElements.map(({ title, path }) => (
              <ListItem key={title} disablePadding>
                <NavLink to={path}>
                  <ListItemButton>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
          <Divider />
        </>
      )}
      <NavigationGroup navElements={listNavElements} dividerAdd={true} />
      <NavigationGroup navElements={generalNavElements} dividerAdd={true} />
      <LogoutNavDiv>
        <LogoutNav />
      </LogoutNavDiv>
    </div>
  ) : null;
};

export default MainNavigationDrawer;
