export function sizeInfoGet(size: null | number): string {
  let result: string = "";
  if (size !== null) {
    if (size < 1000) {
      result = `(${size} KB)`;
    } else {
      let sizeMB: number = size / 1000;
      sizeMB = Math.round(sizeMB * 100) / 100;
      result = `(${sizeMB} MB)`;
    }
  }

  return result;
}
