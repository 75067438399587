import React from "react";
import styled from "styled-components";

export type SearchResultLiteListProps<T> = {
  renderSingle: Function;
  results: T[];
};

function SearchResultLiteList<T>({
  renderSingle,
  results,
}: SearchResultLiteListProps<T>) {
  return (
    <>
      {results.length > 0 && (
        <Div>
          {results.map((result: T, ind: number) => {
            return renderSingle(result, ind);
          })}
        </Div>
      )}
    </>
  );
}

const Div = styled.div`
  div:first-of-type {
    margin-top: 0rem;
  }
`;

export default SearchResultLiteList;
