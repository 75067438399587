import { MessageFile } from "common/message/dto/MessageFile";
import { axiosMatsend } from "../axios/matsend_axios";

export type MessageAddParams = {
  content: any;
  channelId: string;
  fileRemove: MessageFile[];
};

export const messageAdd = async ({
  channelId,
  content,
  fileRemove,
}: MessageAddParams): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.post("message", {
      channelId,
      content,
      fileRemove,
    });
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};

export const messageUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.put(`message/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const messageDelete = async (id: string): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosMatsend.delete(`message/${id}`);
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const messageListDelete = async (
  deletePayload: any
): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosMatsend.delete(`message`, { data: deletePayload });
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const messageMove = async (movePayload: any): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.put(`message/move`, movePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
