import { ChannelResult } from "common/channel/dto/ChannelResult";
import ChannelLiteItem from "./ChannelLiteItem";
import SearchResultLiteList from "components/common/SearchResultLiteList";
import { channelSelectedClassNameGet } from "./ChannelLiteList.logic";

type ChannelLiteListProps = {
  channels: ChannelResult[];
  clickHandle?: Function;
  deleteClickHandle?: Function;
  selectedIds?: string[];
  selectedClass?: string;
};

const ChannelLiteList = ({
  channels,
  clickHandle,
  deleteClickHandle,
  selectedIds,
  selectedClass,
}: ChannelLiteListProps) => {
  console.log("ChannelLiteList render", selectedIds);

  return (
    <SearchResultLiteList<ChannelResult>
      renderSingle={(channel: ChannelResult) => (
        <ChannelLiteItem
          channel={channel}
          key={`channel_lite_${channel.id}`}
          clickHandle={clickHandle}
          deleteClickHandle={deleteClickHandle}
          selectedClass={channelSelectedClassNameGet(
            channel.id,
            selectedClass,
            selectedIds
          )}
        />
      )}
      results={channels}
    />
  );
};

export default ChannelLiteList;
