import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";

export type NavigationGroupProps = {
  navElements: Array<any>;
  dividerAdd?: boolean;
};

const NavigationGroup = ({ navElements, dividerAdd }: NavigationGroupProps) => {
  const location = useLocation();
  const pathName: string = location.pathname;

  return (
    <>
      <List>
        {navElements.map(({ title, path }) => {
          let reloadDocument: boolean = false;
          if (pathName === path) {
            reloadDocument = true;
          }

          return (
            <ListItem key={title} disablePadding>
              <NavLink to={path} reloadDocument={reloadDocument}>
                <ListItemButton>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          );
        })}
      </List>
      {dividerAdd && <Divider />}
    </>
  );
};

export default NavigationGroup;
