import { MessageResult } from "common/message/dto/MessageResult";
import SearchResultList, {
  PageNavigation,
} from "components/common/SearchResultList";
import { useEffect, useState } from "react";
import Message from "../../Message";
import { MESSAGE_LIST_MODES, MESSAGE_SELECT_TYPE } from "./MessageList.const";
import {
  navMsgSelectOptions,
  selMsgIdToggle,
  selectedCheck,
} from "./MessageList.logic";
import useAddNavigation, { AddNavOptions } from "common/modal/AddNavigation";
import useListMode from "common/provider/ListMode";

type MessageListProps = {
  messages: MessageResult[];
  pageNavigation?: PageNavigation | null;
};

const MessageList = ({ messages, pageNavigation }: MessageListProps) => {
  const { listMode, setListMode, listModeIsRegular } = useListMode();
  const [selMsgIds, setSelMsgIds] = useState<string[]>([]);
  const [selectType, setSelectType] = useState<string>(
    MESSAGE_SELECT_TYPE.SELECT
  );
  const addNavActivate = useAddNavigation();
  useEffect(() => {
    if (listModeIsRegular()) {
      setSelMsgIds([]);
    }
  }, [listMode]);

  useEffect(() => {
    if (selMsgIds.length > 0) {
      const navSelectOptions: AddNavOptions = navMsgSelectOptions(
        () => setListMode(MESSAGE_LIST_MODES.REGULAR),
        selMsgIds,
        selectType
      );

      addNavActivate.navOpen(navSelectOptions);
    } else {
      setListMode(MESSAGE_LIST_MODES.REGULAR);
      addNavActivate.navClose();
    }
  }, [selMsgIds.length, selectType]);

  const selectHandle = (
    messageId: string,
    navSelectType: string = MESSAGE_SELECT_TYPE.SELECT
  ) => {
    if (listModeIsRegular()) {
      setListMode(MESSAGE_LIST_MODES.SELECT);
    }

    const selMsgIdsNext = selMsgIdToggle(selMsgIds, messageId);
    setSelMsgIds(selMsgIdsNext);

    if (navSelectType !== selectType) {
      setSelectType(navSelectType);
    }
  };

  return (
    <SearchResultList<MessageResult>
      renderSingle={(message: MessageResult) => (
        <Message
          message={message}
          key={`message_${message.id}`}
          selectHandle={selectHandle}
          selected={selectedCheck(message.id, selMsgIds)}
        />
      )}
      results={messages}
      pageNavigation={pageNavigation}
    />
  );
};

export default MessageList;
