import { useMutation } from "@tanstack/react-query";
import { messageListDelete } from "common/api/matsend/matsend_message_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseMessageListDeleteParams = {
  invalidateKeys?: string[];
};

export const useMessageListDelete = (
  hookParams?: UseMessageListDeleteParams
) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: messageListDeleteMutate } = useMutation({
    mutationFn: async (msgIds: string[]) => await messageListDelete({ msgIds }),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { messageListDeleteMutate };
};
