import styled from "styled-components";
import Button from "@mui/material/Button";
import { defaultValueGet } from "common/parameters_common";
import { MessageEditor } from "./MessageForm.const";
import { useCallback, useState } from "react";
import {
  keyDownEventSubmitCheck,
  messageEditorInitialGet,
  nextMessageEditorGet,
} from "./MessageForm.logic";
import { ReactRefI } from "type/ref.type";
import MessageFormAddNav from "./MessageFormAddNav";
import MessageFormEditor from "./MessageFormEditor";
import { DivField } from "./MessageForm.style";

type MessageFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  defaultValues?: any;
  editCancelClickHandle?: Function;
  editorRef: ReactRefI;
  fileEditRef: ReactRefI;
  fileRemoveRef: ReactRefI;
};

const MessageForm = ({
  onSubmit,
  handleSubmit,
  defaultValues,
  control,
  editCancelClickHandle,
  editorRef,
  fileEditRef,
  fileRemoveRef,
}: MessageFormProps) => {
  const [messageEditor, setMessageEditor] = useState<MessageEditor>(
    messageEditorInitialGet(defaultValueGet(defaultValues, "content", ""))
  );
  const inputKeyDown = useCallback((ev: any) => {
    if (keyDownEventSubmitCheck(ev)) {
      handleSubmit(onSubmit)();
    }
  }, []);

  const contentDefault = defaultValueGet(defaultValues, "content", "");

  const editorToggle = () => {
    const nextMessageEditor: MessageEditor =
      nextMessageEditorGet(messageEditor);
    setMessageEditor(nextMessageEditor);
    console.log("editorToggle");
  };

  return (
    <DivForm>
      <Form>
        <MessageFormAddNav
          editorToggle={editorToggle}
          fileEditRef={fileEditRef}
          fileRemoveRef={fileRemoveRef}
          messageEditor={messageEditor}
        />

        <MessageFormEditor
          control={control}
          contentTextInitial={contentDefault.text}
          messageEditor={messageEditor}
          editorRef={editorRef}
          inputKeyDown={inputKeyDown}
        />

        <DivNav>
          <div>
            <Button onClick={handleSubmit(onSubmit)} type="submit">
              SAVE
            </Button>
            {editCancelClickHandle && (
              <ButtonCancel
                onClick={() => editCancelClickHandle()}
                variant={"outlined"}
              >
                Cancel
              </ButtonCancel>
            )}
          </div>
        </DivNav>
      </Form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled(DivField)`
  margin-top: 0.5rem;
  div {
    text-align: right;
  }
`;

const ButtonCancel = styled(Button)`
  margin-left: 2rem;
`;

export default MessageForm;
