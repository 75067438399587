import { useQuery } from "@tanstack/react-query";
import { testContentGet } from "common/api/matsend/wsn_test_content";
import { QUERY_KEYS } from "constant/react_query";

export const useTestContentGet = () => {
  const { data: testContent } = useQuery(
    [QUERY_KEYS.TEST_CONTENT_GET],
    async () => {
      const result = await testContentGet();
      return result;
    },
    { staleTime: Infinity }
  );
  const result = typeof testContent !== "undefined" ? testContent : null;
  return result;
};
