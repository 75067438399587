import styled from "styled-components";
import { useChannelSearch } from "hooks/matsend/channel/useChannelSearch";
import ChannelLiteList from "components/common/channel/ChannelLiteList";
import { MAX_CHANNEL_START_LIST_COUNT } from "./ChannelStartList.constant";

const ChannelStartList = () => {
  const channelListResult = useChannelSearch({
    limit: MAX_CHANNEL_START_LIST_COUNT,
    skip: 0,
  });

  if (channelListResult === null) {
    return null;
  }

  return <ChannelLiteList channels={channelListResult.items} />;
};

export default ChannelStartList;
