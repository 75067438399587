import { SelectFieldOption } from "components/UI/SelectField";

export const CHANNEL_SEARCH_SORT = {
  SLUG: {
    value: "slug",
    label: "slug",
  },
};

export const CHANNEL_SORT_OPTIONS: SelectFieldOption[] = [
  {
    value: "",
    label: "podrazumevano",
  },
  {
    value: CHANNEL_SEARCH_SORT.SLUG.value,
    label: CHANNEL_SEARCH_SORT.SLUG.label,
  },
];

export const CHANNEL_TYPE = {
  ALL: "",
  PARENT: "parent",
};

export const CHANNEL_TYPE_OPTIONS: SelectFieldOption[] = [
  {
    value: CHANNEL_TYPE.ALL,
    label: "svi",
  },
  {
    value: CHANNEL_TYPE.PARENT,
    label: "parent",
  },
];
