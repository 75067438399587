import styled from "styled-components";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import UserPasswordForm from "./UserPasswordForm";
import { useUserPasswordUpdate } from "hooks/matsend/user/useUserPasswordUpdate";

type UserPasswordUpdateProps = {
  uId: string;
};

const UserPasswordUpdate = ({ uId }: UserPasswordUpdateProps) => {
  const [pwUpdateShow, setPwUpdateShow] = useState<boolean>(false);
  const [pwUpdateCancelShow, setPwUpdateCancelShow] = useState<boolean>(false);
  const { handleSubmit, reset, control } = useForm();
  const { userPasswordUpdateMutate } = useUserPasswordUpdate();

  useEffect(() => {
    if (pwUpdateShow) {
      setPwUpdateCancelShow(true);
    } else {
      setPwUpdateCancelShow(false);
    }
  }, [pwUpdateShow]);

  const onSubmit = async (data: any) => {
    const { pw, pwRe } = data;
    if (typeof pw !== "undefined" && pw !== "" && pw === pwRe) {
      userPasswordUpdateMutate({ id: uId, pw });
      toast.success("User password updated");
    } else {
      toast.error(
        "User password must not be empty and password and retyped password should have same value"
      );
    }
  };

  return (
    <Div>
      {!pwUpdateShow && (
        <ButtonPwUpdate
          onClick={() => setPwUpdateShow(true)}
          variant={"outlined"}
        >
          Update password
        </ButtonPwUpdate>
      )}
      {pwUpdateCancelShow && (
        <ButtonPwUpdate
          onClick={() => setPwUpdateShow(false)}
          variant={"outlined"}
        >
          Cancel password update
        </ButtonPwUpdate>
      )}
      {pwUpdateShow && (
        <UserPasswordForm
          control={control}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      )}
    </Div>
  );
};

const Div = styled.div`
  margin-top: 0.75rem;
`;

const ButtonPwUpdate = styled(Button)`
  margin-top: 1rem;
`;

export default UserPasswordUpdate;
