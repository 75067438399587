import { UserResult } from "common/user/dto/UserResult";
import { useContext, useEffect } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { useUserSearch } from "hooks/matsend/user/useUserSearch";
import { WsNotifyContext } from "common/ws/WsNotify";
import UserLiteItem from "./UserLiteItem";
import SearchResultLiteList from "components/common/SearchResultLiteList";
import { userIdsExclude, userIdsInclude } from "./UserLiteList.logic";

type UserLiteListProps = {
  userType?: string;
  idsInclude?: string[];
  idsExclude?: string[];
  addHandle?: Function;
  removeHandle?: Function;
  removeConfirmMessage?: string;
};

const UserLiteList = ({
  userType,
  idsInclude,
  idsExclude,
  addHandle,
  removeHandle,
  removeConfirmMessage,
}: UserLiteListProps) => {
  const userListResult = useUserSearch({
    limit: 0,
    skip: 0,
    uType: userType,
  });
  const invalidateQuery = useInvalidateQueryByKeys();
  const wsNotifyContextValue = useContext(WsNotifyContext);

  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.MATSEND_USER_SEARCH]);
  }, [invalidateQuery, wsNotifyContextValue.notifyTime]);

  let results: UserResult[] = userListResult ? userListResult["items"] : [];
  if (idsInclude) {
    results = userIdsInclude(results, idsInclude);
  }
  if (idsExclude) {
    results = userIdsExclude(results, idsExclude);
  }

  return (
    <SearchResultLiteList<UserResult>
      renderSingle={(user: UserResult) => (
        <UserLiteItem
          user={user}
          key={`user_${user.id}`}
          addHandle={addHandle}
          removeHandle={removeHandle}
          removeConfirmMessage={removeConfirmMessage}
        />
      )}
      results={results}
    />
  );
};

export default UserLiteList;
