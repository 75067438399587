export type NavElement = {
  title: string;
  path: string;
  adminAccess?: boolean;
};

export type NavElements = {
  [name: string]: NavElement;
};

export const NAV_ELEMENTS: NavElements = {
  START: { title: "Start", path: "/" },
  CHANNEL_NEW: { title: "Channel", path: "/channel/new", adminAccess: true },
  CHANNEL_LIST: { title: "Channel list", path: "/channel/list" },
  USER_NEW: { title: "User", path: "/user/new", adminAccess: true },
  USER_LIST: { title: "User list", path: "/user/list", adminAccess: true },
  USER_PROFILE: { title: "User profile", path: "/user-profile" },
  UPUTSTVO: { title: "Uputstvo", path: "/uputstvo" },
  NOTIFY_TEST: {
    title: "Notify test",
    path: "/notify-test",
    adminAccess: true,
  },
};
