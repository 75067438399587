import { AxiosInstance } from "axios";
import { AUTHORIZATION_DATA } from "./axios_common";
import { authBearer } from "../auth/auth_common";

export async function axiosInstance401Handle(
  axiosInstance: AxiosInstance,
  appName: string,
  axiosInstanceAuth: AUTHORIZATION_DATA
) {
  console.log("interceptor err status 401");
  if (
    axiosInstanceAuth.token === "" ||
    axiosInstanceAuth.refresh === "" ||
    typeof axiosInstanceAuth.token === "undefined" ||
    typeof axiosInstanceAuth.refresh === "undefined"
  ) {
    try {
      const bearerResult = await authBearer(appName);
      axiosInstanceAuthBearerSet(
        axiosInstance,
        axiosInstanceAuth,
        bearerResult
      );
    } catch (err) {
      throw err;
    }
  } else {
    try {
      const bearerResponse = await axiosInstance.post(
        "auth/bearer-refresh",
        {},
        { headers: { Authorization: `Bearer: ${axiosInstanceAuth.refresh}` } }
      );
      axiosInstanceAuth.token = bearerResponse.data.token;
      axiosInstanceAuth.refresh = bearerResponse.data.refresh;
    } catch (err) {
      const bearerResult = await authBearer(appName);
      axiosInstanceAuthBearerSet(
        axiosInstance,
        axiosInstanceAuth,
        bearerResult
      );
    }
  }
}

function axiosInstanceAuthBearerSet(
  axiosInstance: AxiosInstance,
  axiosInstanceAuth: AUTHORIZATION_DATA,
  bearerResult: AUTHORIZATION_DATA
) {
  axiosInstanceAuth.token = bearerResult.token;
  axiosInstanceAuth.refresh = bearerResult.refresh;
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer: ${axiosInstanceAuth.token}`;
}
