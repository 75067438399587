import { Control, Controller, FieldValues } from "react-hook-form";
import { TextField } from "@mui/material";
import { ErrorText } from "./common.style";
import styled from "styled-components";
import { useCallback } from "react";

type Props = {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  defaultValue?: string;
  type?: string;
  onEnterHandle?: Function;
  multiline?: boolean;
  rows?: number;
  autofocus?: boolean;
  onKeyDownHandle?: Function;
};

const InputField = ({
  name,
  control,
  label,
  defaultValue,
  type,
  multiline,
  rows,
  autofocus,
  onKeyDownHandle,
}: Props) => {
  const keyDownHandle = useCallback(
    (ev: any) => (onKeyDownHandle ? onKeyDownHandle(ev) : null),
    []
  );
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, formState: { errors } }) => {
        const errorMessage = errors[name]?.message ?? null;
        return (
          <>
            {errorMessage && typeof errorMessage === "string" && (
              <ErrorText>{errorMessage}</ErrorText>
            )}
            <TextInputField
              onChange={onChange}
              onKeyDown={keyDownHandle}
              label={label}
              defaultValue={defaultValue}
              error={typeof errors[name]?.message !== "undefined"}
              type={type}
              multiline={multiline}
              rows={rows}
              autoFocus={autofocus ?? false}
            />
          </>
        );
      }}
    />
  );
};

const TextInputField = styled(TextField)`
  &&& .MuiFormLabel-root {
    color: currentColor;
  }

  &&& .MuiInputBase-root {
    border: ${(props) => (props?.error ? "red" : "auto")};
  }
`;

export default InputField;
