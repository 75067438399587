import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import { MessageResult } from "common/message/dto/MessageResult";
import { useMessageDelete } from "hooks/matsend/message/useMessageDelete";
import { QUERY_KEYS } from "constant/react_query";
import useConfirm from "common/modal/ConfirmDialog";
import { MouseEventHandler, useState } from "react";
import {
  MESSAGE_DELETE_CONFIRM_TEXT,
  MESSAGE_DELETE_CONFIRM_TITLE,
} from "./MessageSingleNav.const";
import { useAuthCurrent } from "hooks/auth/auth_current";
import { editableAccessCheck } from "./MessageSingleNav.logic";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import { DivActionNav } from "components/common/style/action-nav/DivNav";
import { MESSAGE_SELECT_TYPE } from "../MessageSearchList/MessageList/MessageList.const";

type MessageSingleNavProps = {
  message: MessageResult;
  hideEditNav?: boolean;
  editNavClickHandle?: MouseEventHandler<HTMLDivElement>;
  selectHandle?: Function;
};

const MessageSingleNav = ({
  message,
  hideEditNav,
  editNavClickHandle,
  selectHandle,
}: MessageSingleNavProps) => {
  const [showNav, setShowNav] = useState<boolean>(false);
  const { messageDeleteMutate } = useMessageDelete({
    invalidateKeys: [QUERY_KEYS.MATSEND_MESSAGE_SEARCH],
  });
  const actionConfirm = useConfirm();
  const currentAuth = useAuthCurrent();

  const editableAccess: boolean = editableAccessCheck(
    message.createdBy,
    currentAuth
  );

  const showEditNav: boolean = !hideEditNav && editableAccess;

  const showNavClickHandle = () => {
    setShowNav((currentValue: boolean) => !currentValue);
  };

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: MESSAGE_DELETE_CONFIRM_TITLE,
      message: MESSAGE_DELETE_CONFIRM_TEXT,
    });
    if (check) {
      messageDeleteMutate(message.id);
    }
  };

  const moveNavClickHandle = () => {
    if (selectHandle) {
      selectHandle(message.id, MESSAGE_SELECT_TYPE.MOVE);
    }
  };

  const selectClickHandle = () => {
    if (selectHandle) {
      selectHandle(message.id);
    }
  };

  return (
    <Div>
      <ShowNavDiv onClick={showNavClickHandle}>
        {!showNav && <ExpandMoreIcon fontSize="large" />}
        {showNav && <ExpandLessIcon fontSize="large" />}
      </ShowNavDiv>
      {showNav && (
        <DivActionNav>
          {editableAccess && <DeleteIcon onClick={deleteHandle} />}
          {showEditNav && editNavClickHandle && (
            <div onClick={editNavClickHandle}>
              <EditIcon />
            </div>
          )}
          <ArrowForwardIcon onClick={moveNavClickHandle} />
          {editableAccess && (
            <Button onClick={selectClickHandle}>Select</Button>
          )}
        </DivActionNav>
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
`;

const ShowNavDiv = styled.div`
  cursor: pointer;
`;

export default MessageSingleNav;
