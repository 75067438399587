import { useMutation } from "@tanstack/react-query";
import { channelAccessUpdate } from "common/api/matsend/matsend_channel";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseChannelAccessUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

export const useChannelAccessUpdate = (
  hookParams?: UseChannelAccessUpdateParams
) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: channelAccessUpdateMutate } = useMutation({
    mutationFn: async (params: any) =>
      await channelAccessUpdate(params.id, params.updateAccessPayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { channelAccessUpdateMutate };
};
