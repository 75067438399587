import { useChannelGet } from "hooks/matsend/channel/useChannelGet";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ListModeProvider } from "common/provider/ListMode";
import ChannelMessageNavList from "./ChannelMessageNavList";

const ChannelMessage = () => {
  const { channelId } = useParams();
  const channel = useChannelGet(channelId as string);

  if (!channelId || !channel) {
    return null;
  }

  return (
    <Div>
      <ListModeProvider>
        <ChannelMessageNavList channel={channel} />
      </ListModeProvider>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ChannelMessage;
