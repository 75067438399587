import React, { MouseEventHandler, useContext, useEffect } from "react";
import styled from "styled-components";
import MessageSingleNav from "components/common/message/MessageSingleNav/MessageSingleNav";
import { MessageResult } from "common/message/dto/MessageResult";
import { WsNotifyContext } from "common/ws/WsNotify";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { textContainsHTML } from "common/text_common";
import parse from "html-react-parser";
import MessageFileList from "../../file/MessageFileList";
import DateTimeView from "components/UI/DateTimeView";
import SelectedElement from "components/common/style/selection/SelectedElement";
import { modeCursorGet } from "./MessageRead.logic";
import useListMode from "common/provider/ListMode";

type MessageReadProps = {
  message: MessageResult;
  editNavClickHandle?: MouseEventHandler<HTMLDivElement>;
  selectHandle?: Function;
  selected?: boolean;
};

const MessageRead = ({
  message,
  editNavClickHandle,
  selectHandle,
  selected,
}: MessageReadProps) => {
  const { listMode, listModeIsSelect } = useListMode();
  const wsNotifyContextValue = useContext(WsNotifyContext);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.MATSEND_CHANNEL_GET]);
  }, [invalidateQuery, wsNotifyContextValue.notifyTime]);

  const messageTextContainsHTML: boolean = textContainsHTML(
    message.content.text
  );

  const clickMessageHandle = () => {
    console.log("clickMessageHandle");

    if (listModeIsSelect() && selectHandle) {
      selectHandle(message.id);
    }
  };

  return (
    <Div $cursor={modeCursorGet(listModeIsSelect())}>
      <SelectedElement $selected={selected} onClick={clickMessageHandle}>
        <DivMessageHeader>
          <div>{message.userDisplayName}</div>
          <DivTime>
            <DateTimeView date={message.createdAt} />
          </DivTime>
        </DivMessageHeader>
        <DivMessageContent>
          {!messageTextContainsHTML && <div>{message.content.text}</div>}
          {messageTextContainsHTML && (
            <div>{parse(message.content.text ?? "")}</div>
          )}
          {message.content.file && (
            <MessageFileList messageFiles={message.content.file} />
          )}
        </DivMessageContent>

        {!listModeIsSelect(listMode) && (
          <DivNav>
            <MessageSingleNav
              message={message}
              editNavClickHandle={editNavClickHandle}
              selectHandle={selectHandle}
            />
          </DivNav>
        )}
      </SelectedElement>
    </Div>
  );
};

const Div = styled.div<{ $cursor?: string }>`
  cursor: ${(props) => (props.$cursor ? props.$cursor : "auto")};
`;

const DivNav = styled.div`
  margin-top: 0rem;
`;

const DivMessageHeader = styled.div`
  display: flex;
  margin-top: 1.5rem;
  background-image: linear-gradient(to right, #eee, #fff);
  padding: 0.75rem;
  border-radius: 0.5rem;
`;

const DivTime = styled.div`
  margin-left: 1rem;
`;

const DivMessageContent = styled.div`
  margin-top: 1.5rem;
`;

export default MessageRead;
