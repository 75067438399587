import { useQuery } from "@tanstack/react-query";
import { authCurrentGet } from "common/auth/auth_api_common";
import { authCurrentIsAdmin } from "common/auth/auth_common";
import { QUERY_KEYS } from "constant/react_query";
import { USER_TYPES } from "constant/user";

export const useAuthCurrent = () => {
  const { data: currentAuth } = useQuery(
    [QUERY_KEYS.AUTH_CURRENT_GET],
    authCurrentGet,
    { staleTime: Infinity }
  );
  return currentAuth;
};

export const useIsAdmin = () => {
  const currentAuth = useAuthCurrent();
  const isAdmin: boolean = authCurrentIsAdmin(currentAuth);

  return isAdmin;
};
