import styled from "styled-components";
import { Control, FieldValues } from "react-hook-form";
import SelectField from "components/UI/SelectField";
import { CHANNEL_TYPE_OPTIONS } from "constant/search/channel_search";
import { DivSelectField } from "components/common/style/SelectField/DivSelectField";

type ChannelSelectTypeProps = {
  control: Control<FieldValues, any>;
  fieldName: string;
  defaultValue?: string;
};

const ChannelSelectType = ({
  control,
  fieldName,
  defaultValue,
}: ChannelSelectTypeProps) => {
  return (
    <DivSort>
      <label>Channel type</label>
      <DivSelectField>
        <SelectField
          label=""
          name={fieldName}
          control={control as unknown as Control<FieldValues, any>}
          options={CHANNEL_TYPE_OPTIONS}
          defaultValue={defaultValue}
          onSelect={(val: any) => {}}
        />
      </DivSelectField>
    </DivSort>
  );
};

const DivSort = styled.div`
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  input {
    width: 5rem;
  }
`;

export default ChannelSelectType;
