import { MessageFile } from "common/message/dto/MessageFile";
import { messageFileIsImage } from "common/message/file/message_image";

export function showMessageFileName(messageFile: MessageFile): boolean {
  let show: boolean = true;

  if (messageFileIsImage(messageFile)) {
    show = false;
  }

  return show;
}
