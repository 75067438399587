import { NAV_ELEMENTS } from "constant/navigation";
import { MainNavElement } from "type/navigation/main_navigation.type";

export const GENERAL_NAV_ELEMENTS: MainNavElement[] = [
  NAV_ELEMENTS.USER_PROFILE,
  NAV_ELEMENTS.NOTIFY_TEST,
  NAV_ELEMENTS.UPUTSTVO,
];
export const NEW_ITEM_NAV_ELEMENTS: MainNavElement[] = [
  NAV_ELEMENTS.CHANNEL_NEW,
  NAV_ELEMENTS.USER_NEW,
];
export const LIST_MAIN_NAV_ELEMENTS: MainNavElement[] = [
  NAV_ELEMENTS.CHANNEL_LIST,
  NAV_ELEMENTS.USER_LIST,
];

export const MAIN_NAV_DRAWER_WIDTH = 240;
