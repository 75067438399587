import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import ChannelSingleNav from "components/common/channel/ChannelSingleNav/ChannelSingleNav";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import { WsNotifyContext } from "common/ws/WsNotify";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";

type ChannelReadProps = {
  channel: ChannelResult;
};

const ChannelRead = ({ channel }: ChannelReadProps) => {
  const wsNotifyContextValue = useContext(WsNotifyContext);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.MATSEND_CHANNEL_GET]);
  }, [invalidateQuery, wsNotifyContextValue.notifyTime]);

  return (
    <div>
      <div>{channel.slug}</div>
      <div>{JSON.stringify(channel.accessBy)}</div>
      <DivNav>
        <ChannelSingleNav channel={channel} hideReadNav={true} />
      </DivNav>
    </div>
  );
};

const DivNav = styled.div`
  margin-top: 1.5rem;
`;

export default ChannelRead;
