import { ChannelResult } from "common/channel/dto/ChannelResult";
import ChannelLiteItem from "../../ChannelLiteList/ChannelLiteItem";

type ChannelSelectValueProps = {
  channelSelection: ChannelResult;
  channelSelectionDeleteHandle: Function;
};

const ChannelSelection = ({
  channelSelection,
  channelSelectionDeleteHandle,
}: ChannelSelectValueProps) => {
  return (
    <ChannelLiteItem
      channel={channelSelection}
      deleteClickHandle={channelSelectionDeleteHandle}
    />
  );
};

export default ChannelSelection;
