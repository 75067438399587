import { useEffect, useMemo, useState } from "react";
import { ReactRefI } from "type/ref.type";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import { channelResultDefaultValuesGet } from "common/channel/channel_common";
import { useChannelGet } from "hooks/matsend/channel/useChannelGet";
import ChannelSearchSelect from "../../channel_util/ChannelSearchSelect";
import { parentChannelExcludeIdsGet } from "./ChannelParentSelect.logic";

type ChannelParentSelectProps = {
  channelId: string;
  parentIdDefault: string;
  parentIdRef: ReactRefI;
  channelSelectExcludeIds?: string[];
};

const ChannelParentSelect = ({
  channelId,
  parentIdDefault,
  parentIdRef,
  channelSelectExcludeIds,
}: ChannelParentSelectProps) => {
  const channelResultDefault: ChannelResult = useMemo(
    () => channelResultDefaultValuesGet(),
    []
  );
  const parentChannelDefault = useChannelGet(parentIdDefault);
  const [parentChannel, setParentChannel] =
    useState<ChannelResult>(parentChannelDefault);
  const [parentChannelExcludeIds, setParentChannelExcludeIds] = useState<
    string[]
  >([]);
  useEffect(() => {
    setParentChannel(parentChannelDefault);
  }, [parentChannelDefault]);
  useEffect(() => {
    const excludeIds: string[] = parentChannelExcludeIdsGet(
      channelId,
      channelSelectExcludeIds,
      parentChannel?.id
    );
    setParentChannelExcludeIds(excludeIds);
  }, [parentChannelDefault, parentChannel, channelSelectExcludeIds]);
  useEffect(() => {
    parentIdRef.current = parentChannel?.id;
  }, [parentChannel]);

  const channelSelectHandle = (channelSel: ChannelResult) => {
    if (channelSel) {
      setParentChannel(channelSel);
    }
  };

  const channelSelectionDeleteHandle = (channelId: string) => {
    setParentChannel(channelResultDefault);
  };

  return (
    <ChannelSearchSelect
      onValueSelect={channelSelectHandle}
      label="parent channel"
      channelSelection={parentChannel}
      channelSelectionDeleteHandle={channelSelectionDeleteHandle}
      excludeIds={parentChannelExcludeIds}
    />
  );
};

export default ChannelParentSelect;
