import React from "react";
import { useAuthCurrent } from "hooks/auth/auth_current";
import UserPasswordUpdate from "components/common/user/UserPasswordUpdate";
import { useUserGet } from "hooks/matsend/user/useUserGet";
import UserEdit from "../User/UserEdit";

const UserProfile = () => {
  const currentAuth = useAuthCurrent();
  const user = useUserGet(currentAuth?.uId as string);

  return user && <UserEdit user={user} hideSingleNav={true} />;
};

export default UserProfile;
