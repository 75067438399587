import { axiosMatsend } from "../axios/matsend_axios";

export type MessageFileRetrieveParams = {
  filePath: string;
};

export type MessageFileSearchParams = {
  skip?: number;
  limit?: number;
  channelId?: string;
};

export const messageFileRetrieve = async (
  fileRetrieveParams?: MessageFileRetrieveParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.post(
      `message/file/retrieve`,
      fileRetrieveParams ?? {},
      {
        headers: {
          "response-type": "application/octet-stream",
        },
      }
    );
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

const messageFileResponseFormat = (message: any): any => {
  const result = { ...message };

  return result;
};

export const messageFileSearch = async (
  searchParams?: MessageFileSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.post(
      `message/file/search`,
      searchParams ?? {}
    );
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) =>
      messageFileResponseFormat(item)
    );
  }

  return result;
};
