import { axiosMatsend } from "../axios/matsend_axios";

export type UserSearchParams = {
  skip?: number;
  limit?: number;
  uType?: string;
};

export type UserAddParams = {
  un: string;
  pw: string;
  uType: string;
  email: string;
  firstName: string;
  lastName: string;
};

export const userAdd = async ({
  un,
  pw,
  uType,
  email,
  firstName,
  lastName,
}: UserAddParams): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.post("user", {
      un,
      pw,
      uType,
      email,
      firstName,
      lastName,
    });
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};

const userResponseFormat = (user: any): any => {
  const result = { ...user };

  return result;
};

export const userGet = async (un: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.get(`user/${un}`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const userSearch = async (
  searchParams?: UserSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.post(`user/search`, searchParams ?? {});
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) => userResponseFormat(item));
  }

  return result;
};

export const userUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.put(`user/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const userPasswordUpdate = async (
  id: string,
  pw: string
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.put(`user/${id}/pw-update`, { pw });
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const userDelete = async (id: string): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosMatsend.delete(`user/${id}`);
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
