import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import styled from "styled-components";

export const DivField = styled.div`
  margin-top: 0.2rem;
  &&& > div {
    width: 100%;
  }

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& > div {
      width: 50%;
    }
  }
`;
