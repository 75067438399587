import { Control, Controller, FieldValues } from "react-hook-form";
import { Select, InputLabel, MenuItem } from "@mui/material";
import styled from "styled-components";

export type SelectFieldOption = {
  value: string | number;
  label: string;
};

type Props = {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  defaultValue?: string | number | null;
  options: SelectFieldOption[];
  onSelect?: Function;
};

const SelectField = ({
  name,
  control,
  label,
  defaultValue,
  options,
  onSelect,
}: Props) => {
  // console.log("defaultValue", defaultValue, typeof defaultValue);
  const menuItems = options.map((option: SelectFieldOption, index: number) => (
    <MenuItem value={option.value} key={`option_${index}`}>
      {option.label}
    </MenuItem>
  ));
  const labelId = `${name}-label`;

  return (
    <Div>
      {label !== "" && <SelectLabel id={labelId}>{label}</SelectLabel>}
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange } }) => (
            <SelectElement
              labelId={labelId}
              onChange={(arg) => {
                onChange(arg);
                if (onSelect) {
                  onSelect(arg.target.value);
                }
              }}
              label={label}
              defaultValue={defaultValue}
            >
              {menuItems}
            </SelectElement>
          )}
        />
      </div>
    </Div>
  );
};

const SelectLabel = styled(InputLabel)`
  &&& {
    margin-right: 0.75rem;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const SelectElement = styled(Select)`
  margin-left: 0rem;
`;

export default SelectField;
