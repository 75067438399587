import React, { useState } from "react";
import MessageEdit from "./MessageEdit";
import MessageRead from "./MessageRead";
import { MessageResult } from "common/message/dto/MessageResult";
import styled from "styled-components";

type MessageComponentProps = {
  message: MessageResult;
  selectHandle?: Function;
  selected?: boolean;
};

const MessageComponent = ({
  message,
  selectHandle,
  selected,
}: MessageComponentProps) => {
  const [mode, setMode] = useState<string>("");

  if (!message) {
    return null;
  }

  const editNavClickHandle = () => {
    setMode("edit");
  };

  const readModeSet = () => {
    setMode("read");
  };

  return (
    <>
      <Div>
        <Div>
          {mode !== "edit" && (
            <MessageRead
              message={message}
              editNavClickHandle={editNavClickHandle}
              selectHandle={selectHandle}
              selected={selected}
            />
          )}
          {mode === "edit" && (
            <MessageEdit message={message} readModeSet={readModeSet} />
          )}
        </Div>
      </Div>
    </>
  );
};

const Div = styled.div`
  margin-top: 1rem;
`;

export default MessageComponent;
