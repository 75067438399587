import useConfirm from "common/modal/ConfirmDialog";
import { useChannelSearch } from "hooks/matsend/channel/useChannelSearch";
import { MESSAGE_MOVE_CHANNEL_COUNT_MAX } from "./MessageMoveNav.const";
import { useForm } from "react-hook-form";
import {
  messageMoveFormDefaultValuesGet,
  messageMoveNotificationGet,
} from "./MessageMoveNav.logic";
import { useRef } from "react";
import MessageMoveForm from "./MessageMoveForm";
import { useMessageMove } from "hooks/matsend/message/useMessageMove";
import { QUERY_KEYS } from "constant/react_query";
import toast from "react-hot-toast";
import { useMessageGet } from "hooks/matsend/message/useMessageGet";

type MessageMoveNavProps = {
  msgIds: string[];
  setShowMove: Function;
  afterMoveHandle?: Function;
};

const MessageMoveNav = ({
  msgIds,
  setShowMove,
  afterMoveHandle,
}: MessageMoveNavProps) => {
  const actionConfirm = useConfirm();
  const channelListResult = useChannelSearch({
    limit: MESSAGE_MOVE_CHANNEL_COUNT_MAX,
    skip: 0,
  });
  const { handleSubmit, control } = useForm({
    defaultValues: messageMoveFormDefaultValuesGet(),
  });
  const channelIdsRef = useRef<any>([]);
  const { messageMoveMutate } = useMessageMove({
    invalidateKeysArray: [
      [QUERY_KEYS.MATSEND_MESSAGE_SEARCH],
      [QUERY_KEYS.MATSEND_MESSAGE_GET],
    ],
  });
  const message = useMessageGet(msgIds[0]);

  const onSubmit = async (data: any) => {
    const moveType: string = data.messageMoveType;
    const movePayload = {
      messageIds: msgIds,
      channelIds: channelIdsRef.current,
      moveType,
    };

    messageMoveMutate(movePayload);
    setShowMove(false);

    const notification: string = messageMoveNotificationGet(moveType);
    toast.success(notification);
    if (afterMoveHandle) {
      afterMoveHandle();
    }
  };

  const moveEditCancelClickHandle = () => {
    channelIdsRef.current = [];
    setShowMove(false);
  };

  return (
    channelListResult !== null &&
    message && (
      <MessageMoveForm
        channelId={message.channelId}
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        defaultValues={messageMoveFormDefaultValuesGet()}
        moveEditCancelClickHandle={moveEditCancelClickHandle}
        channelIdsRef={channelIdsRef}
      />
    )
  );
};

export default MessageMoveNav;
