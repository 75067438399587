import { channelMessagesFilesLink } from "common/channel/channel_link_common";
import ChannelChildListNav from "components/common/channel/ChannelChildListNav";
import MessageNew from "components/common/message/MessageNew";
import React from "react";
import styled from "styled-components";
import { ChannelMessageNavLink } from "../../ChannelMessageNavLink";
import MessageSearchList from "components/common/message/MessageSearchList";
import useListMode from "common/provider/ListMode/ListMode";

type ChannelMessageNavListProps = {
  channel: any;
};

const ChannelMessageNavList = ({ channel }: ChannelMessageNavListProps) => {
  const { listModeIsRegular } = useListMode();

  return (
    <>
      <DivChannelTitleNav>
        <div>{channel.slug}</div>
        <ChannelMessageNavLink to={channelMessagesFilesLink(channel.id)}>
          CHANNEL FILES
        </ChannelMessageNavLink>
      </DivChannelTitleNav>
      <ChannelChildListNav channel={channel} />
      {listModeIsRegular() && <MessageNew channelId={channel.id} />}
      <ListDiv>
        <MessageSearchList channelId={channel.id} />
      </ListDiv>
    </>
  );
};

const DivChannelTitleNav = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  font-size: 1.2rem;
`;

const ListDiv = styled.div`
  margin-top: 1rem;
`;

export default ChannelMessageNavList;
