import { MessageFile } from "common/message/dto/MessageFile";
import { axiosMatsend } from "../axios/matsend_axios";

export type MessageSearchParams = {
  skip?: number;
  limit?: number;
  channelId?: string;
};

const messageResponseFormat = (message: any): any => {
  const result = { ...message };
  result.createdAt = new Date(result.createdAt);
  result.modifiedAt = new Date(result.modifiedAt);

  return result;
};

export const messageGet = async (slug: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.get(`message/${slug}`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const messageSearch = async (
  searchParams?: MessageSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.post(`message/search`, searchParams ?? {});
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) => messageResponseFormat(item));
  }

  return result;
};
