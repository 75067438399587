import { AddNavOptions } from "common/modal/AddNavigation";
import MessageListSelectNav from "./MessageListSelectNav";
import { MESSAGE_SELECT_TYPE } from "./MessageList.const";

export const selMsgIdToggle = (ids: string[], id: string): string[] => {
  let result: string[] = [];
  if (!ids.includes(id)) {
    result = [...ids, id];
  } else {
    result = ids.filter((idVal: string) => idVal !== id);
  }

  return result;
};

export const selectedCheck = (id: string, ids: string[]): boolean => {
  let result: boolean = false;
  if (ids.includes(id)) {
    result = true;
  }

  return result;
};

export const navMsgSelectOptions = (
  navCloseHandle: Function,
  msgIds: string[],
  selectType: string = MESSAGE_SELECT_TYPE.SELECT
): AddNavOptions => {
  return {
    title: "",
    navContent: (
      <MessageListSelectNav
        msgIds={msgIds}
        onActionComplete={navCloseHandle}
        selectType={selectType}
      />
    ),
    navCloseHandle,
  };
};
