import { MESSAGE_MOVE_TYPE } from "constant/search/message_search";
import {
  MESSAGE_MOVE_NOTIFICATION,
  MESSAGE_MOVE_TYPE_FIELD_NAME,
} from "./MessageMoveNav.const";

export const messageMoveFormDefaultValuesGet = () => {
  return {
    [MESSAGE_MOVE_TYPE_FIELD_NAME]: MESSAGE_MOVE_TYPE.COPY,
  };
};

export const messageMoveNotificationGet = (moveType: string): string => {
  let notification: string = "";
  if (moveType === MESSAGE_MOVE_TYPE.COPY) {
    notification = MESSAGE_MOVE_NOTIFICATION.COPY;
  } else if (moveType === MESSAGE_MOVE_TYPE.MOVE) {
    notification = MESSAGE_MOVE_NOTIFICATION.MOVE;
  }

  return notification;
};
