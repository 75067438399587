import { MessageFile } from "common/message/dto/MessageFile";

export function uploadMessageFileListGet(
  ev: any,
  fileList: MessageFile[]
): MessageFile[] {
  console.log("fileUploadHandle", ev);
  const fileListNext: MessageFile[] = [...fileList];
  ev.files.forEach((file: any) => {
    const messageFile: MessageFile = {
      path: file.path,
    };
    fileListNext.push(messageFile);
  });

  return fileListNext;
}

export function removeMessageFileListGet(
  fileRemoveList: MessageFile[],
  messageFile: MessageFile
): MessageFile[] {
  const fileRemoveListNext: MessageFile[] = [...fileRemoveList, messageFile];

  return fileRemoveListNext;
}
