import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import { IconButton } from "@mui/material";
import { channelSearchFieldsGet } from "common/channel/channel_search";
import SearchText from "components/common/search_sort/SearchText";
import SearchSort from "components/common/search_sort/SearchSort";
import { SEARCH_NAMES } from "constant/search/search";
import { CHANNEL_SORT_OPTIONS } from "constant/search/channel_search";
import { useIsAdmin } from "hooks/auth/auth_current";
import ChannelSelectType from "../channel_util/ChannelSelectType";

type ChannelSearchFiltersProps = {
  searchHandle: Function;
};

const ChannelSearchFilters = ({ searchHandle }: ChannelSearchFiltersProps) => {
  const isAdmin: boolean = useIsAdmin();
  const { handleSubmit, control } = useForm({
    defaultValues: channelSearchFieldsGet(null),
  });

  const defaultVals: any = channelSearchFieldsGet(null);

  const onSubmit = async (data: any) => {
    console.log(data);
    searchHandle(data);
  };

  return (
    <div>
      <DivSearchOrder>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SearchText
            fieldName={SEARCH_NAMES.SEARCH_TEXT}
            defaultValue={defaultVals[SEARCH_NAMES.SEARCH_TEXT]}
            label="Tekst pretrazivanja"
            control={control}
          />
          <ChannelSelectType
            control={control}
            fieldName={SEARCH_NAMES.CHANNEL_TYPE}
            defaultValue={defaultVals[SEARCH_NAMES.CHANNEL_TYPE]}
          />
          <SearchSort
            control={control}
            sortByName={SEARCH_NAMES.SORT_BY}
            sortOrderName={SEARCH_NAMES.SORT_ORDER}
            sortByDefaultValue={""}
            sortOrderDefaultValue={""}
            sortOptions={CHANNEL_SORT_OPTIONS}
          />
          <DivSubmit>
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </DivSubmit>
        </Form>
      </DivSearchOrder>
    </div>
  );
};

const DivSearchOrder = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  svg {
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
`;

const Form = styled.form`
  &&& {
    display: flex;
  }
`;

const DivSubmit = styled.div`
  margin-left: 1.5rem;
  display: flex;
`;

export default ChannelSearchFilters;
