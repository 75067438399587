import ChannelChildListNav from "components/common/channel/ChannelChildListNav";
import { useChannelGet } from "hooks/matsend/channel/useChannelGet";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ChannelMessageNavLink } from "../ChannelMessageNavLink";
import { channelMessagesLinkSingle } from "common/channel/channel_link_common";
import { useMessageFileSearch } from "hooks/matsend/message/file/useMessageFileSearch";
import MessageFileList from "components/common/message/file/MessageFileList";

const ChannelMessageFiles = () => {
  const { channelId } = useParams();
  const channel = useChannelGet(channelId as string);
  const messageListResult = useMessageFileSearch({ channelId });

  if (!channelId || !channel) {
    return null;
  }

  return (
    <Div>
      <DivChannelTitleNav>
        <div>{channel.slug}</div>
        <ChannelMessageNavLink to={channelMessagesLinkSingle(channel.id)}>
          CHANNEL MESSAGES
        </ChannelMessageNavLink>
      </DivChannelTitleNav>
      <ChannelChildListNav channel={channel} />
      {messageListResult && messageListResult.items.length > 0 && (
        <ListDiv>
          <MessageFileList messageFiles={messageListResult.items} />
        </ListDiv>
      )}
    </Div>
  );
};

const DivChannelTitleNav = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  font-size: 1.2rem;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListDiv = styled.div`
  margin-top: 1rem;
`;

export default ChannelMessageFiles;
