export const TINY_MCE_INITIAL_HEIGHT: number = 300;

export const MESSAGE_EDITOR = {
  SIMPLE: {
    value: "simpleEditor",
    label: "simple text",
  },
  HTML_FORMAT: {
    value: "htmlFormat",
    label: "format",
  },
};

export type MessageEditor = "htmlFormat" | "simpleEditor";
