import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MatsendStart from "components/pages/MatsendStart";
import LoginPage from "components/pages/LoginPage";
import LogoutPage from "components/pages/LogoutPage";
import MainView from "./MainView";
import LoginView from "./LoginView";
import UserGuide from "components/pages/UserGuide";
import { ConfirmDialogProvider } from "common/modal/ConfirmDialog";
import { AddNavigationProvider } from "common/modal/AddNavigation";
import Matsend from "components/pages/Matsend";
import ChannelList from "components/pages/channel/ChannelList";
import ChannelNew from "components/pages/channel/ChannelNew";
import Channel from "components/pages/channel/Channel";
import WsNotify from "common/ws/WsNotify";
import NotifyTest from "components/pages/NotifyTest";
import UserNew from "components/pages/user/UserNew";
import User from "components/pages/user/User";
import ChannelMessage from "components/pages/message/ChannelMessage";
import UserProfile from "components/pages/user/UserProfile";
import UserList from "components/common/user/UserList";
import ChannelMessageFile from "components/pages/message/ChannelMessageFile";

export default function MatsendMainContent() {
  return (
    <ConfirmDialogProvider>
      <AddNavigationProvider>
        <Router>
          <Routes>
            <Route
              element={
                <WsNotify>
                  <MainView />
                </WsNotify>
              }
            >
              <Route path="/" element={<MatsendStart />} />
              <Route path="/channel">
                <Route path="list" element={<ChannelList />} />
                <Route path="new" element={<ChannelNew />} />
                <Route
                  path=":channelId/message/file"
                  element={<ChannelMessageFile />}
                />
                <Route path=":channelId/message" element={<ChannelMessage />} />
                <Route path=":channelId" element={<Channel />} />
              </Route>
              <Route path="/user">
                <Route path="list" element={<UserList />} />
                <Route path="new" element={<UserNew />} />
                <Route path=":userId" element={<User />} />
              </Route>
              <Route path="/matsend" element={<Matsend />} />
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/uputstvo" element={<UserGuide />} />
              <Route path="/notify-test" element={<NotifyTest />} />
            </Route>
            <Route element={<LoginView />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
            </Route>
          </Routes>
        </Router>
      </AddNavigationProvider>
    </ConfirmDialogProvider>
  );
}
