import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { defaultValueGet } from "common/parameters_common";
import InputField from "components/UI/InputField";
import ChannelParentSelect from "./ChannelParentSelect";
import { ReactRefI } from "type/ref.type";

type ChannelFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  reset: Function;
  defaultValues?: any;
  parentIdDefault: string;
  parentIdRef: ReactRefI;
  channelSelectExcludeIds?: string[];
};

const ChannelForm = ({
  onSubmit,
  handleSubmit,
  reset,
  defaultValues,
  parentIdDefault,
  control,
  parentIdRef,
  channelSelectExcludeIds,
}: ChannelFormProps) => {
  const slugDefault = defaultValueGet(defaultValues, "slug", "");

  return (
    <DivForm>
      <form>
        <DivField>
          <InputField
            name="slug"
            control={control}
            label="slug"
            defaultValue={slugDefault}
          />
        </DivField>

        <DivField>
          <ChannelParentSelect
            channelId={defaultValueGet(defaultValues, "id", "")}
            parentIdDefault={parentIdDefault}
            parentIdRef={parentIdRef}
            channelSelectExcludeIds={channelSelectExcludeIds}
          />
        </DivField>

        <DivNav>
          <Button onClick={handleSubmit(onSubmit)} type="submit">
            Submit
          </Button>
          <Button onClick={() => reset()} variant={"outlined"}>
            Reset
          </Button>
        </DivNav>
      </form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
`;

const DivField = styled.div`
  margin-top: 1rem;
`;

const DivNav = styled.div`
  margin-top: 2rem;
`;

export default ChannelForm;
