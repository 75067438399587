import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useChannelGet } from "hooks/matsend/channel/useChannelGet";
import ChannelEdit from "./ChannelEdit";
import ChannelRead from "./ChannelRead";

const ChannelComponent = () => {
  const { channelId } = useParams();
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<string>("");
  const channel = useChannelGet(channelId as string);
  useEffect(() => {
    const paramsMode = searchParams.get("mode") ?? "";
    if (paramsMode !== mode) {
      setMode(paramsMode);
    }
  }, [searchParams, channel, mode]);

  if (!channel) {
    return null;
  }

  return (
    <>
      {mode !== "edit" && <ChannelRead channel={channel} />}
      {mode === "edit" && <ChannelEdit channel={channel} />}
    </>
  );
};

export default ChannelComponent;
