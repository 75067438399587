import { SelectFieldOption } from "components/UI/SelectField";

export const USER_SEARCH_SORT = {
  UN: {
    value: "un",
    label: "username",
  },
};

export const USER_SORT_OPTIONS: SelectFieldOption[] = [
  {
    value: "",
    label: "podrazumevano",
  },
  { value: USER_SEARCH_SORT.UN.value, label: USER_SEARCH_SORT.UN.label },
];
