import styled from "styled-components";
import FileUpload from "components/UI/FileUpload";
import { axiosMatsend } from "common/api/axios/matsend_axios";
import { useEffect, useState } from "react";
import { MessageFile } from "common/message/dto/MessageFile";
import MessageFileEditItem from "./MessageFileEditItem";
import SearchResultLiteList from "components/common/SearchResultLiteList";
import {
  MESSAGE_FILE_FIELD_NAME,
  MESSAGE_UP_URL,
} from "./MessageFileEdit.const";
import {
  removeMessageFileListGet,
  uploadMessageFileListGet,
} from "./MessageFileEdit.logic";
import { ReactRefI } from "type/ref.type";

type MessageFileEditProps = {
  fileEditRef: ReactRefI;
  fileRemoveRef: ReactRefI;
};

const MessageFileEdit = ({
  fileEditRef,
  fileRemoveRef,
}: MessageFileEditProps) => {
  const [fileList, setFileList] = useState<MessageFile[]>(fileEditRef.current);
  const [fileRemoveList, setFileRemoveList] = useState<MessageFile[]>([]);
  useEffect(() => {
    fileEditRef.current = fileList;
  }, [fileList]);
  useEffect(() => {
    fileRemoveRef.current = fileRemoveList;
  }, [fileRemoveList]);

  const fileUploadHandle = (ev: any) => {
    const fileListNext: MessageFile[] = uploadMessageFileListGet(ev, fileList);
    setFileList(fileListNext);
  };

  const fileNameDefault: string = "";

  const deleteHandle = (ind: number) => {
    const fileListNext: MessageFile[] = fileList.filter(
      (messageFile: MessageFile, i: number) => i !== ind
    );
    const fileRemoveListNext: MessageFile[] = removeMessageFileListGet(
      fileRemoveList,
      fileList[ind]
    );
    setFileRemoveList(fileRemoveListNext);
    setFileList(fileListNext);
  };

  return (
    <>
      {fileList.length > 0 && (
        <DivList>
          <SearchResultLiteList<MessageFile>
            renderSingle={(messageFile: MessageFile, ind: number) => (
              <DivMessageFileItem key={`message_file_${ind}`}>
                <MessageFileEditItem
                  messageFile={messageFile}
                  deleteHandle={deleteHandle}
                  ind={ind}
                />
              </DivMessageFileItem>
            )}
            results={fileList}
          />
        </DivList>
      )}
      <div>
        <FileUpload
          axiosInstance={axiosMatsend}
          fileFieldName={MESSAGE_FILE_FIELD_NAME}
          fileUploadHandle={fileUploadHandle}
          uploadUrl={MESSAGE_UP_URL}
          fileNameDefault={fileNameDefault}
          multiple={true}
          upLabel="file"
        />
      </div>
    </>
  );
};

const DivMessageFileItem = styled.div`
  margin-top: 0.75rem;
`;

const DivList = styled.div`
  margin-top: 0rem;
  margin-bottom: 1rem;
`;

export default MessageFileEdit;
