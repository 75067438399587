import { AxiosInstance } from "axios";
import { apiMatsendUrlGet } from "../api_common";
import { API_APP_NAMES } from "../api_constants";
import { authorizationObDefault, axiosInstanceCreate } from "./axios_common";
import { axiosInstance401Handle } from "./axios_intercepter_common";

const matsendUrlBase: string = apiMatsendUrlGet();

export const matsendApiAuth = authorizationObDefault();

const API_MATSEND_RETRY_LIMIT: number = 3;

export const axiosMatsend: AxiosInstance = axiosInstanceCreate(matsendUrlBase);

axiosMatsend.interceptors.response.use(null, async (err) => {
  if (typeof err.config.retryLimit === "undefined") {
    err.config.retryLimit = API_MATSEND_RETRY_LIMIT;
  }
  if (err.config.retryLimit === 0) {
    throw err;
  }
  err.config.retryLimit--;
  if (err.response.status === 401) {
    try {
      await axiosInstance401Handle(
        axiosMatsend,
        API_APP_NAMES.MATSEND,
        matsendApiAuth
      );
    } catch (err) {
      throw err;
    }
  }

  const nextConfig = { ...err.config };
  nextConfig.headers["Authorization"] = `Bearer: ${matsendApiAuth.token}`;
  const response = await axiosMatsend(nextConfig);
  return response;
});
