import { useMutation } from "@tanstack/react-query";
import { messageUpdate } from "common/api/matsend/matsend_message_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseMessageUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
  onSuccessAdditionalAction?: Function;
};

export const useMessageUpdate = (hookParams?: UseMessageUpdateParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: messageUpdateMutate } = useMutation({
    mutationFn: async (params: any) =>
      await messageUpdate(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }

      if (hookParams?.onSuccessAdditionalAction) {
        hookParams.onSuccessAdditionalAction();
      }
    },
  });

  return { messageUpdateMutate };
};
