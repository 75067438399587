import styled from "styled-components";
import { UserResult } from "common/user/dto/UserResult";
import { Button } from "@mui/material";
import UserLiteRemoveNav from "./UserLiteRemoveNav";

type UserLiteSingleNavProps = {
  user: UserResult;
  addHandle?: Function;
  removeHandle?: Function;
  removeConfirmMessage?: string;
};

const UserLiteSingleNav = ({
  user,
  addHandle,
  removeHandle,
  removeConfirmMessage,
}: UserLiteSingleNavProps) => {
  return (
    <DivNav>
      {removeHandle && (
        <UserLiteRemoveNav
          userId={user.id}
          removeHandle={removeHandle}
          removeConfirmMessage={removeConfirmMessage}
        />
      )}
      {addHandle && (
        <Button onClick={() => addHandle(user.id)} variant={"outlined"}>
          Add
        </Button>
      )}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-top: 0.75rem;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;

export default UserLiteSingleNav;
