import { SelectFieldOption } from "components/UI/SelectField";

export const MESSAGE_SEARCH_NAMES = {
  CHANNEL_ID: "channelId",
};

export const MESSAGE_SEARCH_SORT = {
  CREATED_AT: {
    value: "createdAt",
    label: "vreme unosa",
  },
};

export const MESSAGE_SORT_OPTIONS: SelectFieldOption[] = [
  {
    value: "",
    label: "podrazumevano",
  },
  {
    value: MESSAGE_SEARCH_SORT.CREATED_AT.value,
    label: MESSAGE_SEARCH_SORT.CREATED_AT.label,
  },
];

export const MESSAGE_MOVE_TYPE = {
  COPY: "copy",
  MOVE: "move",
};

export const MESSAGE_MOVE_TYPE_OPTIONS: SelectFieldOption[] = [
  {
    value: MESSAGE_MOVE_TYPE.COPY,
    label: "copy",
  },
  {
    value: MESSAGE_MOVE_TYPE.MOVE,
    label: "move",
  },
];
