export const MESSAGE_DELETE_CONFIRM_TEXT: string =
  "Are you sure you want to delete message";

export const MESSAGE_DELETE_CONFIRM_TITLE: string = "Message";

export const MESSAGE_LIST_DELETE_CONFIRM_TEXT: string =
  "Are you sure you want to delete messages";

export const MESSAGE_LIST_DELETE_CONFIRM_TITLE: string = "Messages";

export const MESSAGE_FILE_DELETE_CONFIRM_TEXT: string =
  "Are you sure you want to delete message file";

export const MESSAGE_FILE_DELETE_CONFIRM_TITLE: string = "Message file";
