import { useMutation } from "@tanstack/react-query";
import {
  MessageAddParams,
  messageAdd,
} from "common/api/matsend/matsend_message_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseMessageAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useMessageAdd = (hookParams?: UseMessageAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: messageAddMutate } = useMutation({
    mutationFn: async (messageAddParams: MessageAddParams) =>
      await messageAdd(messageAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { messageAddMutate };
};
