import { channelResultDefaultValuesGet } from "common/channel/channel_common";
import { axiosMatsend } from "../axios/matsend_axios";

export type ChannelSearchParams = {
  skip?: number;
  limit?: number;
};

export type ChannelAddParams = {
  slug: string;
  parentChannelId: string;
};

export type ChannelAccessUpdateParams = {
  accessBy: string[];
};

export const channelAdd = async ({
  slug,
  parentChannelId,
}: ChannelAddParams): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.post("channel", { slug, parentChannelId });
  } catch (error) {}
  if (response && response.status === 201) {
    result = response.data;
  }

  return result;
};

const channelResponseFormat = (channel: any): any => {
  const result = { ...channel };

  return result;
};

export const channelGet = async (id: string): Promise<any> => {
  let result: any = null;
  let response: any = channelResultDefaultValuesGet();
  try {
    if (id !== "") {
      response = await axiosMatsend.get(`channel/${id}`);
    }
  } catch (err) {
    // console.error(err);
  }
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const channelSearch = async (
  searchParams?: ChannelSearchParams
): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosMatsend.post(`channel/search`, searchParams ?? {});
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
    result.items = result.items.map((item: any) => channelResponseFormat(item));
  }

  return result;
};

export const channelUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.put(`channel/${id}`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const channelAccessUpdate = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosMatsend.put(`channel/${id}/access-by`, updatePayload);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const channelDelete = async (id: string): Promise<boolean> => {
  let result: boolean = false;
  let response: any;
  try {
    response = await axiosMatsend.delete(`channel/${id}`);
    result = true;
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
