import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useConfirm from "common/modal/ConfirmDialog";
import {
  MESSAGE_LIST_DELETE_CONFIRM_TEXT,
  MESSAGE_LIST_DELETE_CONFIRM_TITLE,
} from "components/common/message/MessageSingleNav/MessageSingleNav.const";
import { useMessageListDelete } from "hooks/matsend/message/useMessageListDelete";
import { QUERY_KEYS } from "constant/react_query";
import toast from "react-hot-toast";
import { DivActionNav } from "components/common/style/action-nav/DivNav";
import styled from "styled-components";
import MessageMoveNav from "components/common/message/MessageSingleNav/MessageMoveNav";
import { MESSAGE_SELECT_TYPE } from "../MessageList.const";

type MessageListSelectNavProps = {
  msgIds: string[];
  onActionComplete?: Function;
  selectType: string;
};

const MessageListSelectNav = ({
  msgIds,
  onActionComplete,
  selectType,
}: MessageListSelectNavProps) => {
  const actionConfirm = useConfirm();
  const { messageListDeleteMutate } = useMessageListDelete({
    invalidateKeys: [QUERY_KEYS.MATSEND_MESSAGE_SEARCH],
  });
  const [showMove, setShowMove] = useState<boolean>(
    selectType === MESSAGE_SELECT_TYPE.MOVE
  );

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: MESSAGE_LIST_DELETE_CONFIRM_TITLE,
      message: MESSAGE_LIST_DELETE_CONFIRM_TEXT,
    });
    if (check) {
      console.log("delete messages, msgIds", msgIds);
      messageListDeleteMutate(msgIds);
      toast.success("Messages deleted");

      if (onActionComplete) {
        onActionComplete();
      }
    }
  };

  const moveHandle = () => {
    console.log("moveHandle");
    if (msgIds.length > 0) {
      setShowMove(true);
    }
  };

  const afterMoveHandle = () => {
    if (onActionComplete) {
      onActionComplete();
    }
  };

  return (
    <Div>
      <DivNote>Selected: {msgIds.length}</DivNote>
      {selectType === MESSAGE_SELECT_TYPE.SELECT && !showMove && (
        <DivActionNav>
          <ArrowForwardIcon onClick={moveHandle} />

          <DeleteIcon onClick={deleteHandle} />
        </DivActionNav>
      )}
      {showMove && (
        <MessageMoveNav
          msgIds={msgIds}
          setShowMove={setShowMove}
          afterMoveHandle={afterMoveHandle}
        />
      )}
    </Div>
  );
};

const Div = styled.div`
  margin-bottom: 1rem;
`;

const DivNote = styled.div`
  margin-bottom: 0.5rem;
`;

export default MessageListSelectNav;
