import { useMutation } from "@tanstack/react-query";
import { channelUpdate } from "common/api/matsend/matsend_channel";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseChannelUpdateParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

export const useChannelUpdate = (hookParams?: UseChannelUpdateParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: channelUpdateMutate } = useMutation({
    mutationFn: async (params: any) =>
      await channelUpdate(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { channelUpdateMutate };
};
