import { useMutation } from "@tanstack/react-query";
import { messageDelete } from "common/api/matsend/matsend_message_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseMessageDeleteParams = {
  invalidateKeys?: string[];
};

export const useMessageDelete = (hookParams?: UseMessageDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: messageDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await messageDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { messageDeleteMutate };
};
