import { useQuery } from "@tanstack/react-query";
import {
  ChannelSearchParams,
  channelSearch,
} from "common/api/matsend/matsend_channel";
import { QUERY_KEYS } from "constant/react_query";

export const useChannelSearch = (searchParams?: ChannelSearchParams) => {
  const { data: channel } = useQuery(
    [QUERY_KEYS.MATSEND_CHANNEL_SEARCH, searchParams],
    async () => {
      const result = await channelSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof channel !== "undefined" && channel !== "" ? channel : null;
  return result;
};
