import { UserResult } from "common/user/dto/UserResult";
import SearchResultList, {
  PageNavigation,
} from "components/common/SearchResultList";
import { useContext, useEffect, useState } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { UserSearchParams } from "common/api/matsend/matsend_user";
import { useUserSearch } from "hooks/matsend/user/useUserSearch";
import { userSearchFieldsGet } from "common/user/user_search";
import { WsNotifyContext } from "common/ws/WsNotify";
import { USER_PER_PAGE_MAX } from "constant/user";
import UserSearchFilters from "./UserSearchFilters";
import UserItem from "./UserItem";

const UserList = () => {
  const [searchParams, setSearchParams] = useState<UserSearchParams>({
    limit: USER_PER_PAGE_MAX,
    skip: 0,
  });
  const userListResult = useUserSearch(searchParams);
  const invalidateQuery = useInvalidateQueryByKeys();
  const wsNotifyContextValue = useContext(WsNotifyContext);

  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.MATSEND_USER_SEARCH]);
  }, [searchParams, invalidateQuery, wsNotifyContextValue.notifyTime]);

  let results: UserResult[] = userListResult ? userListResult["items"] : [];
  const resTotal: number = userListResult ? userListResult["resTotal"] : 0;
  const pageNavigationHandle = (pageNum: number) => {
    const skip = pageNum * USER_PER_PAGE_MAX;
    setSearchParams({ ...searchParams, skip });
  };
  const pageNavigation: PageNavigation = {
    resTotal,
    limit: USER_PER_PAGE_MAX,
    skip: searchParams.skip ?? 0,
    pageNavigationHandle,
  };
  const searchHandle = (formData: any) => {
    const searchVals = userSearchFieldsGet(formData);

    setSearchParams({
      ...searchParams,
      ...searchVals,
      skip: 0,
    });
  };

  return (
    <div>
      <UserSearchFilters searchHandle={searchHandle} />
      <SearchResultList<UserResult>
        renderSingle={(user: UserResult) => (
          <UserItem user={user} key={`user_${user.id}`} />
        )}
        results={results}
        pageNavigation={pageNavigation}
      />
    </div>
  );
};

export default UserList;
