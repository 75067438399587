export const channelLinkSingle = (
  channelId: string,
  isEditMode: boolean = false
): string => {
  let link = `/channel/${channelId}`;
  if (isEditMode) {
    link += "?mode=edit";
  }
  return link;
};

export const channelMessagesLinkSingle = (channelId: string): string => {
  const link = `/channel/${channelId}/message`;
  return link;
};

export const channelMessagesFilesLink = (channelId: string): string => {
  const link = channelMessagesLinkSingle(channelId) + `/file`;
  return link;
};
