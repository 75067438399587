import { textContainsHTML } from "common/text_common";
import { MESSAGE_EDITOR, MessageEditor } from "./MessageForm.const";
import { MessageFile } from "common/message/dto/MessageFile";

export const editorTypeNavLabel = (editorType: MessageEditor): string => {
  const label: string =
    editorType === MESSAGE_EDITOR.SIMPLE.value
      ? MESSAGE_EDITOR.HTML_FORMAT.label
      : MESSAGE_EDITOR.SIMPLE.label;
  return label;
};

export const messageEditorInitialGet = (content: any): MessageEditor => {
  let messageEditor: MessageEditor = MESSAGE_EDITOR.SIMPLE
    .value as MessageEditor;
  if (textContainsHTML(content.text)) {
    messageEditor = MESSAGE_EDITOR.HTML_FORMAT.value as MessageEditor;
  }

  return messageEditor;
};

export const nextMessageEditorGet = (
  editorType: MessageEditor
): MessageEditor => {
  const nextMessageEditor: MessageEditor = (
    editorType === MESSAGE_EDITOR.SIMPLE.value
      ? MESSAGE_EDITOR.HTML_FORMAT.value
      : MESSAGE_EDITOR.SIMPLE.value
  ) as MessageEditor;
  return nextMessageEditor;
};

export const messageFileInitialGet = (content: any): MessageFile[] => {
  let fileList: MessageFile[] = [];

  return fileList;
};

export const keyDownEventSubmitCheck = (ev: any): boolean => {
  let check: boolean = false;
  if (ev.ctrlKey && ev.keyCode === 13) {
    check = true;
  }

  return check;
};
