import { useQuery } from "@tanstack/react-query";
import {
  MessageSearchParams,
  messageSearch,
} from "common/api/matsend/matsend_message";
import { QUERY_KEYS } from "constant/react_query";

export const useMessageSearch = (searchParams?: MessageSearchParams) => {
  const { data: message } = useQuery(
    [QUERY_KEYS.MATSEND_MESSAGE_SEARCH, searchParams],
    async () => {
      const result = await messageSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof message !== "undefined" && message !== "" ? message : null;
  return result;
};
