import { MESSAGE_EDITOR, TINY_MCE_INITIAL_HEIGHT } from "../MessageForm.const";
import { DivField } from "../MessageForm.style";
import InputField from "components/UI/InputField";
import TinyMCEEditor from "components/UI/TinyMCEEditor";
import { ReactRefI } from "type/ref.type";
import { MESSAGE_FORM_EDITOR_LABEL } from "./MessageFormEditor.const";
import { MessageEditor } from "../MessageForm.type";

type MessageFormEditorProps = {
  contentTextInitial: string;
  messageEditor: MessageEditor;
  control: any;
  editorRef: ReactRefI;
  inputKeyDown: Function;
};

const MessageFormEditor = ({
  contentTextInitial,
  messageEditor,
  control,
  editorRef,
  inputKeyDown,
}: MessageFormEditorProps) => {
  return (
    <>
      {messageEditor === MESSAGE_EDITOR.SIMPLE.value && (
        <DivField>
          <InputField
            name="contentText"
            control={control}
            label={MESSAGE_FORM_EDITOR_LABEL}
            defaultValue={contentTextInitial}
            multiline
            rows={3}
            autofocus={true}
            onKeyDownHandle={inputKeyDown}
          />
        </DivField>
      )}
      {messageEditor === MESSAGE_EDITOR.HTML_FORMAT.value && (
        <DivField>
          <TinyMCEEditor
            initialHeight={TINY_MCE_INITIAL_HEIGHT}
            editorRef={editorRef}
            initialValue={contentTextInitial}
          />
        </DivField>
      )}
    </>
  );
};

export default MessageFormEditor;
