import styled from "styled-components";
import React from "react";
import { MessageFile } from "common/message/dto/MessageFile";
import MessageFileReadItem from "./MessageFileReadItem";
import SearchResultLiteList from "components/common/SearchResultLiteList";
import useListMode from "common/provider/ListMode";

type MessageFileListProps = {
  messageFiles: MessageFile[];
};

const MessageFileList = ({ messageFiles }: MessageFileListProps) => {
  const { listModeIsSelect } = useListMode();

  return (
    <DivList>
      <SearchResultLiteList<MessageFile>
        renderSingle={(messageFile: MessageFile, ind: number) => (
          <DivMessageFileItem key={`message_file_${ind}`}>
            <MessageFileReadItem
              messageFile={messageFile}
              disableLinkDownload={listModeIsSelect()}
            />
          </DivMessageFileItem>
        )}
        results={messageFiles}
      />
    </DivList>
  );
};

const DivMessageFileItem = styled.div`
  margin-top: 0.75rem;
`;

const DivList = styled.div`
  margin-bottom: 0rem;
`;

export default MessageFileList;
