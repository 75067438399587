import { UserResult } from "common/user/dto/UserResult";

export const userIdsInclude = (
  users: UserResult[],
  ids: string[]
): UserResult[] => {
  const result: UserResult[] = users.filter((user: UserResult) =>
    ids.includes(user.id)
  );
  return result;
};

export const userIdsExclude = (
  users: UserResult[],
  ids: string[]
): UserResult[] => {
  const result: UserResult[] = users.filter(
    (user: UserResult) => !ids.includes(user.id)
  );
  return result;
};
