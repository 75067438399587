import { ChannelResult } from "./dto/ChannelResult";

export const channelResultDefaultValuesGet = (): ChannelResult => {
  return {
    id: "",
    slug: "",
    accessBy: [],
    parentChannelId: "",
    childCount: 0,
  };
};
