import { ChannelResult } from "common/channel/dto/ChannelResult";

export const channelSelectAdd = (
  channel: ChannelResult,
  selChannels: ChannelResult[]
): ChannelResult[] => {
  const result: ChannelResult[] = [...selChannels, channel];
  return result;
};

export const moveLabelFormGet = (idsCount: number): string => {
  let label: string = "";
  if (idsCount > 0) {
    label = "OK";
  }

  return label;
};

export const channelIdsGet = (channels: ChannelResult[]): string[] => {
  return channels.map((channel: ChannelResult) => channel.id);
};
