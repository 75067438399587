import React, { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import NavItemSmallScreen from "./NavItemSmallScreen";
import { NAV_ELEMENTS } from "constant/navigation";
import { MainNavElement } from "type/navigation/main_navigation.type";
import { useIsAdmin } from "hooks/auth/auth_current";
import LogoutNav from "components/navigation/LogoutNav";

type NavItemProps = {
  navItem: MainNavElement;
};

const MainNavigationSmallScreen = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const isAdmin: boolean = useIsAdmin();

  const clickHandle = useCallback(() => setShowMenu(false), []);

  const menuShowClickHandle = () => {
    setShowMenu((currentValue: boolean) => {
      return !currentValue;
    });
  };

  const NavItem = ({ navItem }: NavItemProps) => {
    return (
      <NavItemSmallScreen
        path={navItem.path}
        title={navItem.title}
        clickHandle={clickHandle}
      />
    );
  };

  return (
    <DivSmallNav>
      <DivButtonMenu>
        <DivButton>
          <IconButton aria-label="Example" onClick={menuShowClickHandle}>
            <FontAwesomeIcon icon={faBars as any} />
          </IconButton>
        </DivButton>
        {showMenu && (
          <DivMenu>
            <NavItem navItem={NAV_ELEMENTS.START} />
            <NavItem navItem={NAV_ELEMENTS.UPUTSTVO} />
            {isAdmin && (
              <NavItem
                navItem={{
                  ...NAV_ELEMENTS.CHANNEL_NEW,
                  title: NAV_ELEMENTS.CHANNEL_NEW.title + " new",
                }}
              />
            )}
            <NavItem navItem={NAV_ELEMENTS.CHANNEL_LIST} />
            {isAdmin && (
              <NavItem
                navItem={{
                  ...NAV_ELEMENTS.USER_NEW,
                  title: NAV_ELEMENTS.USER_NEW.title + " new",
                }}
              />
            )}
            {isAdmin && <NavItem navItem={NAV_ELEMENTS.USER_LIST} />}
            <NavItem navItem={NAV_ELEMENTS.USER_PROFILE} />
            <LogoutNav />
          </DivMenu>
        )}
      </DivButtonMenu>
    </DivSmallNav>
  );
};

const DivSmallNav = styled.div.attrs({ className: "screen-small" })`
  align-self: end;
`;

const DivButtonMenu = styled.div.attrs({ className: "screen-small" })`
  display: flex;
  flex-direction: column;
`;

const DivMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
`;

const DivButton = styled.div`
  text-align: right;
`;

export default MainNavigationSmallScreen;
