import React from "react";
import styled from "styled-components";
import { PageNavigation } from "./SearchResultList";
import CustomTablePagination from "./CustomTablePagination";
import { TablePagination } from "@mui/material";

export interface PageNavigationProps extends PageNavigation {}

const SearchPageNavigation = ({
  resTotal,
  skip,
  limit,
  pageNavigationHandle,
}: PageNavigationProps) => {
  const page: number = Math.trunc(skip / limit);
  const pageChangeHandle = (
    ev: React.MouseEvent<HTMLButtonElement> | null,
    pageNum: number
  ) => {
    console.log("onPageChange", pageNum);
    pageNavigationHandle(pageNum);
  };
  return (
    <DivNav>
      {resTotal > 0 && (
        <table>
          <tbody>
            <tr>
              <TablePagination
                count={resTotal}
                page={page}
                rowsPerPage={limit}
                onPageChange={pageChangeHandle}
              />
            </tr>
          </tbody>
        </table>
      )}
      {/* <Divider sx={{ border: "0.1rem solid", marginTop: "2rem" }} /> */}
    </DivNav>
  );
};

const DivNav = styled.div`
  margin-bottom: 1rem;
`;

export default SearchPageNavigation;
