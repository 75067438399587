import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import useConfirm from "common/modal/ConfirmDialog";
import { REMOVE_USER_CONFIRM_MESSAGE } from "./UserLiteRemoveNav.const";

type UserDeleteNavProps = {
  userId: string;
  removeHandle?: Function;
  removeConfirmMessage?: string;
};

const UserLiteRemoveNav = ({
  userId,
  removeHandle,
  removeConfirmMessage,
}: UserDeleteNavProps) => {
  const actionConfirm = useConfirm();

  const removeClickHandle = async () => {
    const confirmMessage: string =
      removeConfirmMessage ?? REMOVE_USER_CONFIRM_MESSAGE;
    const check = await actionConfirm({
      title: "User",
      message: confirmMessage,
    });
    if (check && removeHandle) {
      removeHandle(userId);
    }
  };

  return <DeleteIconNav onClick={removeClickHandle} />;
};

const DeleteIconNav = styled(DeleteIcon)`
  margin-left: 0rem;
`;

export default UserLiteRemoveNav;
