import styled from "styled-components";
import { MESSAGE_MOVE_TYPE_FIELD_NAME } from "../MessageMoveNav.const";
import ChannelLiteList from "components/common/channel/ChannelLiteList";
import { Control, FieldValues } from "react-hook-form";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import MessageMoveSelect from "./MessageMoveSelect";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import { ReactRefI } from "type/ref.type";
import {
  channelIdsGet,
  channelSelectAdd,
  moveLabelFormGet,
} from "./MessageMoveForm.logic";
import { MESSAGE_MOVE_TYPE } from "constant/search/message_search";
import ChannelSearchSelect from "components/common/channel/channel_util/ChannelSearchSelect";
import { DivSelectField } from "components/common/style/SelectField/DivSelectField";

type MessageMoveFormProps = {
  channelId: string;
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  defaultValues?: any;
  moveEditCancelClickHandle: Function;
  channelIdsRef: ReactRefI;
};

const MessageMoveForm = ({
  control,
  handleSubmit,
  onSubmit,
  moveEditCancelClickHandle,
  channelIdsRef,
}: MessageMoveFormProps) => {
  const [selChannels, setSelChannels] = useState<ChannelResult[]>([]);
  const [moveLabel, setMoveLabel] = useState<string>("");
  useEffect(() => {
    channelIdsRef.current = channelIdsGet(selChannels);
  }, [selChannels]);

  const channelSelect = (channel: ChannelResult) => {
    if (channel) {
      console.log(channel);

      const selChannelsNext: ChannelResult[] = channelSelectAdd(
        channel,
        selChannels
      );
      setSelChannels(selChannelsNext);
      const moveLabelNext: string = moveLabelFormGet(selChannelsNext.length);

      if (moveLabelNext !== moveLabel) {
        setMoveLabel(moveLabelNext);
      }
    }
  };

  const deleteHandle = (chId: string) => {
    const selChannelsNext = selChannels.filter(
      (channel: ChannelResult) => channel.id !== chId
    );
    setSelChannels(selChannelsNext);
    console.log("chId", chId);
  };

  return (
    <MoveForm>
      <DivSelect>
        <MessageMoveSelect
          defaultValue={MESSAGE_MOVE_TYPE.COPY}
          control={control as unknown as Control<FieldValues, any>}
          fieldName={MESSAGE_MOVE_TYPE_FIELD_NAME}
        />
      </DivSelect>

      <DivSelectField>
        <ChannelSearchSelect
          onValueSelect={channelSelect}
          excludeIds={channelIdsGet(selChannels)}
        />
      </DivSelectField>
      <DivList>
        <ChannelLiteList
          channels={selChannels}
          clickHandle={() => null}
          deleteClickHandle={deleteHandle}
          selectedIds={[]}
          selectedClass="selected-item"
        />
      </DivList>
      <DivNav>
        {moveLabel !== "" && (
          <ButtonOk
            onClick={handleSubmit(onSubmit)}
            variant={"outlined"}
            type="submit"
          >
            {moveLabel}
          </ButtonOk>
        )}
        {/* <Button
          onClick={() => moveEditCancelClickHandle()}
          variant={"outlined"}
        >
          Cancel
        </Button> */}
      </DivNav>
    </MoveForm>
  );
};

const MoveForm = styled.form`
  display: flex;
  margin-top: 0.75rem;
  margin-left: 1rem;
`;

const DivSelect = styled.div`
  div {
    margin-left: 0rem;
  }
`;

const DivList = styled.div`
  margin-left: 2rem;
`;

const DivNav = styled.div`
  margin-top: 0rem;
  margin-left: 2rem;
`;

const ButtonOk = styled(Button)`
  margin-right: 2rem;
`;

export default MessageMoveForm;
