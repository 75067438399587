import { AuthResponse } from "common/auth/auth_api_common";
import { authCurrentIsAdmin } from "common/auth/auth_common";
import { MessageResult } from "common/message/dto/MessageResult";
import { AddNavOptions } from "common/modal/AddNavigation";
import MessageMoveNav from "./MessageMoveNav";

export function editableAccessCheck(
  messageCreatedBy: string,
  currentAuth?: AuthResponse
): boolean {
  let check: boolean = false;
  if (authCurrentIsAdmin(currentAuth)) {
    check = true;
  } else if (
    typeof currentAuth !== "undefined" &&
    messageCreatedBy === currentAuth.uId
  ) {
    check = true;
  }

  return check;
}
