import { MainNavElement } from "type/navigation/main_navigation.type";

export const filterNavElementsByAccess = (
  navElements: MainNavElement[],
  isAdmin: boolean
) =>
  navElements.filter(
    (navElement: MainNavElement) => !(navElement.adminAccess && !isAdmin)
  );

export const navArraysGet = (
  navArrays: MainNavElement[][],
  isAdmin: boolean
): MainNavElement[][] => {
  const result: MainNavElement[][] = navArrays.map(
    (navArray: MainNavElement[]) => filterNavElementsByAccess(navArray, isAdmin)
  );
  return result;
};
