import { useMutation } from "@tanstack/react-query";
import { userDelete } from "common/api/matsend/matsend_user";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseUserDeleteParams = {
  invalidateKeys?: string[];
};

export const useUserDelete = (hookParams?: UseUserDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: userDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await userDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { userDeleteMutate };
};
