import { MessageResult } from "./dto/MessageResult";

export const messageFormDefaultValuesGet = (message?: MessageResult) => {
  if (message) {
    return {
      channelId: message.channelId,
      createdBy: message.createdBy,
      createdAt: message.createdAt,
      modifiedAt: message.modifiedAt,
      content: message.content,
    };
  } else {
    return {
      channelId: "",
      createdBy: "",
      createdAt: new Date(),
      modifiedAt: new Date(),
      content: { text: "" },
    };
  }
};
