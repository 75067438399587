import { ChannelResult } from "common/channel/dto/ChannelResult";

export const excludeIdsFilter = (
  channels: ChannelResult[],
  excludeIds?: string[]
): ChannelResult[] => {
  let result = [...channels];
  if (excludeIds && excludeIds.length > 0) {
    result = result.filter(
      (channel: ChannelResult) => !excludeIds.includes(channel.id)
    );
  }

  return result;
};

export const searchSelectResults = (
  channels: ChannelResult[],
  excludeIds?: string[]
): ChannelResult[] => {
  let result = [...channels];
  if (excludeIds) {
    result = excludeIdsFilter(result, excludeIds);
  }

  return result;
};

export const showSearchSelect = (channelSelection: ChannelResult): boolean => {
  let result: boolean = false;
  if (channelSelection.id !== "") {
    result = true;
  }

  return result;
};
