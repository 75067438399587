import styled from "styled-components";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import { QUERY_KEYS } from "constant/react_query";
import { useState } from "react";
import { useChannelSearch } from "hooks/matsend/channel/useChannelSearch";
import { SEARCH_NAMES } from "constant/search/search";
import ChannelLiteList from "../../ChannelLiteList";

type ChannelChildListProps = {
  parentChannelId: string;
};

const ChannelChildList = ({ parentChannelId }: ChannelChildListProps) => {
  const channelListResult = useChannelSearch({
    limit: 0,
    skip: 0,
    [SEARCH_NAMES.PARENT_CHANNEL_ID]: parentChannelId,
  });

  if (channelListResult === null) {
    return null;
  }

  return (
    <Div>
      <ChannelLiteList channels={channelListResult.items} />
    </Div>
  );
};

const Div = styled.div`
  margin-top: 0.75rem;
`;

const DivNav = styled.div`
  cursor: pointer;
`;

const DivChildList = styled.div`
  margin-top: 0.75rem;
`;

export default ChannelChildList;
