import * as React from "react";
import { Typography } from "@mui/material";

type DateTimeViewProps = {
  date: Date;
  sx?: any;
};

const DateTimeView = ({ date, sx }: DateTimeViewProps) => {
  const dateView = date.toLocaleString();
  return (
    <Typography variant="body1" sx={sx ?? {}}>
      {dateView}
    </Typography>
  );
};

export default DateTimeView;
