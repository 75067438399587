import styled from "styled-components";
import { Control, FieldValues } from "react-hook-form";
import SelectField from "components/UI/SelectField";
import { MESSAGE_MOVE_TYPE_OPTIONS } from "constant/search/message_search";

type MessageMoveSelectProps = {
  control: Control<FieldValues, any>;
  fieldName: string;
  defaultValue: any;
};

const MessageMoveSelect = ({
  control,
  fieldName,
  defaultValue,
}: MessageMoveSelectProps) => {
  return (
    <Div>
      <SelectField
        label=""
        name={fieldName}
        control={control as unknown as Control<FieldValues, any>}
        options={MESSAGE_MOVE_TYPE_OPTIONS}
        defaultValue={defaultValue}
        onSelect={(val: any) => {}}
      />
    </Div>
  );
};

const Div = styled.div`
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  input {
    width: 5rem;
  }
`;

export default MessageMoveSelect;
