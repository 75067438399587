import { MessageFile } from "common/message/dto/MessageFile";
import { retrieveMessageFileSrc } from "common/message/file/message_file";
import { sizeInfoGet } from "common/message/message_common";
import { fileNameGet } from "common/util/file/file_common";

export async function messageImageSrc(filePath: string) {
  const imageSrc = await retrieveMessageFileSrc(filePath);

  return imageSrc;
}

export function imageTitleGet(messageFile: MessageFile): string {
  const sizeInfo: string = sizeInfoGet(messageFile.size ?? null);
  const imageName: string = fileNameGet(messageFile.path);
  let title: string = imageName;
  if (sizeInfo !== "") {
    title += " " + sizeInfo;
  }

  return title;
}
