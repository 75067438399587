import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "hooks/hook_util/debounce/useDebounce";
import { ReactRefI } from "type/ref.type";
import { CHANNEL_PER_PAGE_MAX } from "constant/channel";
import { ChannelSearchParams } from "common/api/matsend/matsend_channel";
import { useChannelSearch } from "hooks/matsend/channel/useChannelSearch";
import { SEARCH_NAMES } from "constant/search/search";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import {
  searchSelectResults,
  showSearchSelect,
} from "./ChannelSearchSelect.logic";
import ChannelSelection from "./ChannelSelection";

type ChannelSearchSelectProps = {
  excludeIds?: string[];
  onValueSelect?: Function;
  label?: string;
  channelSelection?: ChannelResult;
  channelSelectionDeleteHandle?: Function;
};

const getOptionLabel = (option: ChannelResult) => option.slug;

const ChannelSearchSelect = ({
  excludeIds,
  onValueSelect,
  label,
  channelSelection,
  channelSelectionDeleteHandle,
}: ChannelSearchSelectProps) => {
  const [value, setValue] = useState<ChannelResult | null>(null);
  const searchOptionsTimeRef: ReactRefI = useRef<null | number>(null);
  const [searchParams, setSearchParams] = useState<ChannelSearchParams>({
    limit: CHANNEL_PER_PAGE_MAX,
    skip: 0,
  });
  const channelListResult = useChannelSearch(searchParams);
  const handleChannelSearch = useDebounce((searchText: string) => {
    console.log("debounced handleChannelSearch, searchText", searchText);
    setSearchParams({
      ...searchParams,
      [SEARCH_NAMES.SEARCH_TEXT]: searchText,
      skip: 0,
    });
  }, 500);
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    const currentTime = new Date().getTime();

    if (
      searchOptionsTimeRef.current === null ||
      searchOptionsTimeRef.current < currentTime
    ) {
      invalidateQuery.invalidateQueriesByKeys([
        QUERY_KEYS.MATSEND_CHANNEL_SEARCH,
      ]);
      searchOptionsTimeRef.current = currentTime;
    }
  }, [searchParams, invalidateQuery]);

  const results: ChannelResult[] = channelListResult
    ? searchSelectResults(channelListResult["items"], excludeIds)
    : [];

  const changeHandle = (event: any, newValue: ChannelResult | null) => {
    if (onValueSelect) {
      onValueSelect(newValue);
      setValue(null);
    }
  };

  const textChangeHandle = (event: any) => {
    handleChannelSearch(event.target.value);
  };

  return (
    <DivNav>
      {channelSelection &&
        channelSelectionDeleteHandle &&
        showSearchSelect(channelSelection) && (
          <DivSelection>
            <ChannelSelection
              channelSelection={channelSelection}
              channelSelectionDeleteHandle={channelSelectionDeleteHandle}
            />
          </DivSelection>
        )}
      <Autocomplete
        getOptionLabel={getOptionLabel}
        value={value}
        id="combo-box-demo"
        options={results}
        blurOnSelect
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? "Channel"}
            onChange={textChangeHandle}
          />
        )}
        onChange={changeHandle}
      />
    </DivNav>
  );
};

const DivNav = styled.div`
  display: flex;
  margin-top: 0rem;
`;

const DivSelection = styled.div`
  margin-right: 1rem;
`;

export default ChannelSearchSelect;
