import styled from "styled-components";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";
import { channelMessagesLinkSingle } from "common/channel/channel_link_common";
import DeleteIcon from "@mui/icons-material/Close";

type ChannelLiteItemProps = {
  channel: ChannelResult;
  clickHandle?: Function;
  deleteClickHandle?: Function;
  selectedClass?: string;
};

const ChannelLiteItem = ({
  channel,
  clickHandle,
  deleteClickHandle,
  selectedClass,
}: ChannelLiteItemProps) => {
  return (
    <Div>
      {!clickHandle && (
        <NavLinkUndecorated to={channelMessagesLinkSingle(channel.id)}>
          <div>{channel.slug}</div>
        </NavLinkUndecorated>
      )}
      {clickHandle && (
        <DivChannel
          onClick={() => clickHandle(channel)}
          className={
            typeof selectedClass === "undefined" || selectedClass === ""
              ? ""
              : selectedClass
          }
        >
          {channel.slug}
        </DivChannel>
      )}
      {deleteClickHandle && (
        <DivDelete>
          <DeleteIcon
            onClick={() => deleteClickHandle(channel.id)}
            fontSize="small"
          />
        </DivDelete>
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-top: 1rem;
  line-height: 1.25rem;
`;

const DivChannel = styled.div`
  cursor: pointer;
`;

const DivDelete = styled.div`
  margin-left: 1rem;
  cursor: pointer;
`;

export default ChannelLiteItem;
