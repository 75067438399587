import { useQuery } from "@tanstack/react-query";
import { messageGet } from "common/api/matsend/matsend_message";
import { QUERY_KEYS } from "constant/react_query";

export const useMessageGet = (id: string) => {
  const { data: message } = useQuery(
    [QUERY_KEYS.MATSEND_MESSAGE_GET, id],
    async () => {
      const result = await messageGet(id);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof message !== "undefined" && message !== "" ? message : null;
  return result;
};
