import { MessageFile } from "common/message/dto/MessageFile";
import MessageImage from "components/common/message/file/MessageImage";
import { messageFileIsImage } from "common/message/file/message_image";
import MessageFileClickActLink from "../../MessageFileClickActLink";
import MessageFileReadNav from "./MessageFileReadNav";

type MessageFileReadItemProps = {
  messageFile: MessageFile;
  disableLinkDownload?: boolean;
};

const MessageFileReadItem = ({
  messageFile,
  disableLinkDownload,
}: MessageFileReadItemProps) => {
  return (
    <div>
      <MessageFileReadNav
        messageFile={messageFile}
        disableLinkDownload={disableLinkDownload}
      />
      {messageFileIsImage(messageFile) &&
        ((!disableLinkDownload && (
          <MessageFileClickActLink messageFilePath={messageFile.path}>
            <MessageImage messageFile={messageFile} />
          </MessageFileClickActLink>
        )) ||
          (disableLinkDownload && <MessageImage messageFile={messageFile} />))}
    </div>
  );
};

export default MessageFileReadItem;
