import styled from "styled-components";
import { MessageFile } from "common/message/dto/MessageFile";
import { useEffect, useState } from "react";
import { imageTitleGet, messageImageSrc } from "./MessageImage.logic";

type MessageImageProps = {
  messageFile: MessageFile;
};

const MessageImage = ({ messageFile }: MessageImageProps) => {
  const [imageSrc, setImageSrc] = useState<any>(null);
  useEffect(() => {
    const imagePath: string = messageFile.small?.path ?? messageFile.path;
    messageImageSrc(imagePath).then((imSrc) => {
      setImageSrc(imSrc);
    });
  }, []);

  const title: string = imageTitleGet(messageFile);

  return <div>{imageSrc && <Img src={imageSrc} title={title} />}</div>;
};

const Img = styled.img`
  margin-top: 0.5rem;
  max-height: 200px;
`;

export default MessageImage;
