export const parentChannelExcludeIdsGet = (
  channelId: string,
  channelSelectExcludeIds?: string[],
  parentChannelId?: string
): string[] => {
  let result: string[] = [];
  if (channelSelectExcludeIds) {
    result = [...channelSelectExcludeIds];
  }
  if (channelId) {
    result.push(channelId);
  }
  if (parentChannelId) {
    result.push(parentChannelId);
  }

  return result;
};
