import { useMutation } from "@tanstack/react-query";
import { channelDelete } from "common/api/matsend/matsend_channel";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseChannelDeleteParams = {
  invalidateKeys?: string[];
};

export const useChannelDelete = (hookParams?: UseChannelDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: channelDeleteMutate } = useMutation({
    mutationFn: async (id: string) => await channelDelete(id),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { channelDeleteMutate };
};
