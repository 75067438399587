import styled from "styled-components";
import Button from "@mui/material/Button";
import { useState } from "react";
import UserLiteList from "components/common/user/UserLiteList";
import { USER_TYPES } from "constant/user";
import { USER_CHANNEL_ACCESS_REMOVE_MESSAGE } from "./ChannelAccessEdit.logic";
import toast from "react-hot-toast";
import { useChannelAccessUpdate } from "hooks/matsend/channel/useChannelAccessUpdate";
import { QUERY_KEYS } from "constant/react_query";

type ChannelAccessEditProps = {
  channelId: string;
  defaultValue: string[];
};

const ChannelAccessEdit = ({
  channelId,
  defaultValue,
}: ChannelAccessEditProps) => {
  const [accessBy, setAccessBy] = useState<string[]>(defaultValue);
  const { channelAccessUpdateMutate } = useChannelAccessUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.MATSEND_CHANNEL_SEARCH],
      [QUERY_KEYS.MATSEND_CHANNEL_GET],
    ],
  });

  const handleAccessUpdate = () => {
    channelAccessUpdateMutate({
      id: channelId,
      updateAccessPayload: {
        accessBy,
      },
    });

    toast.success("Channel access updated");
  };

  const addHandle = (userId: string) => {
    console.log("addHandle", userId);
    const accessByNext = [...accessBy, userId];
    setAccessBy(accessByNext);
  };

  const removeHandle = (userId: string) => {
    console.log("removeHandle", userId);
    const accessByNext = accessBy.filter((uId: string) => uId !== userId);
    setAccessBy(accessByNext);
  };

  return (
    <Div>
      <DivTitle>User access to channel</DivTitle>
      <DivAccessBy>
        <div>Access by:</div>
        <div>
          <UserLiteList
            userType={USER_TYPES.STANDARD.value}
            idsInclude={accessBy}
            removeHandle={removeHandle}
            removeConfirmMessage={USER_CHANNEL_ACCESS_REMOVE_MESSAGE}
          />
        </div>
      </DivAccessBy>
      <DivAdd>
        <DivAddTitle>Add user:</DivAddTitle>
        <div>
          <UserLiteList
            userType={USER_TYPES.STANDARD.value}
            idsExclude={accessBy}
            addHandle={addHandle}
          />
        </div>
      </DivAdd>
      <DivNav>
        <Button onClick={handleAccessUpdate} variant={"outlined"}>
          Save
        </Button>
      </DivNav>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

const DivNav = styled.div`
  margin-top: 2.5rem;
`;

const DivAdd = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  border-top: 0.2rem solid #dddddd;
`;

const DivAddTitle = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
`;

const DivTitle = styled.div`
  font-size: 1.2rem;
`;

const DivAccessBy = styled.div`
  margin-top: 1rem;
`;

export default ChannelAccessEdit;
