import { MessageFile } from "common/message/dto/MessageFile";
import { MessageResult } from "common/message/dto/MessageResult";
import { ReactRefI } from "type/ref.type";

function updateContentTextGet(
  messageContentText: string,
  editorRef: ReactRefI,
  dataContentText?: string
): string {
  let updateContentText: string = messageContentText;
  if (editorRef.current !== null) {
    updateContentText = editorRef.current.getContent();
  } else if (typeof dataContentText !== "undefined") {
    updateContentText = dataContentText;
  }

  return updateContentText;
}

export function messageUpdatePayloadGet(
  message: MessageResult,
  formData: any,
  editorRef: ReactRefI,
  fileEditRef: ReactRefI,
  fileRemoveRef: ReactRefI
): any {
  const updateContentText: string = updateContentTextGet(
    message.content.text,
    editorRef,
    formData.contentText
  );
  const messageFiles: MessageFile[] =
    fileEditRef.current !== null ? fileEditRef.current : [];
  const content = {
    text: updateContentText,
    file: messageFiles,
  };
  const updatePayload = {
    content,
    fileRemove: fileRemoveRef.current,
  };

  return updatePayload;
}
