import { MESSAGE_EDITOR } from "../MessageForm.const";
import { MessageEditor } from "../MessageForm.type";

export const editorTypeNavLabel = (editorType: MessageEditor): string => {
  const label: string =
    editorType === MESSAGE_EDITOR.SIMPLE.value
      ? MESSAGE_EDITOR.HTML_FORMAT.label
      : MESSAGE_EDITOR.SIMPLE.label;
  return label;
};
