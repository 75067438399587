import { Button } from "@mui/material";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import styled from "styled-components";
import { ReactRefI } from "type/ref.type";
import MessageFileEdit from "../MessageFileEdit";
import { editorTypeNavLabel } from "./MessageFormAddNav.logic";
import { MessageEditor } from "../MessageForm.type";

type MessageFormAddNavProps = {
  editorToggle: Function;
  messageEditor: MessageEditor;
  fileEditRef: ReactRefI;
  fileRemoveRef: ReactRefI;
};

const MessageFormAddNav = ({
  editorToggle,
  messageEditor,
  fileEditRef,
  fileRemoveRef,
}: MessageFormAddNavProps) => {
  return (
    <DivAddNav>
      <ButtonEditorType onClick={() => editorToggle()} variant="text">
        {editorTypeNavLabel(messageEditor)}
      </ButtonEditorType>
      <DivMessageFileEdit>
        <MessageFileEdit
          fileEditRef={fileEditRef}
          fileRemoveRef={fileRemoveRef}
        />
      </DivMessageFileEdit>
    </DivAddNav>
  );
};

const DivAddNav = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &&& {
    width: 100%;
  }

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& {
      width: 50%;
    }
  }
`;

const ButtonEditorType = styled(Button)`
  margin-left: 0rem;
`;

const DivMessageFileEdit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}
`;

export default MessageFormAddNav;
