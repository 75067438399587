import { useQuery } from "@tanstack/react-query";
import {
  MessageFileSearchParams,
  messageFileSearch,
} from "common/api/matsend/matsend_message_file";
import { QUERY_KEYS } from "constant/react_query";

export const useMessageFileSearch = (
  searchParams?: MessageFileSearchParams
) => {
  const { data: messageFile } = useQuery(
    [QUERY_KEYS.MATSEND_MESSAGE_FILE_SEARCH, searchParams],
    async () => {
      const result = await messageFileSearch(searchParams);
      return result;
    },
    { staleTime: Infinity }
  );
  const result =
    typeof messageFile !== "undefined" && messageFile !== ""
      ? messageFile
      : null;
  return result;
};
