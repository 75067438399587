import { useMutation } from "@tanstack/react-query";
import { messageMove } from "common/api/matsend/matsend_message_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type useMessageMoveParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
  onSuccessAdditionalAction?: Function;
};

export const useMessageMove = (hookParams?: useMessageMoveParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: messageMoveMutate } = useMutation({
    mutationFn: async (movePayload: any) => await messageMove(movePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }

      if (hookParams?.onSuccessAdditionalAction) {
        hookParams.onSuccessAdditionalAction();
      }
    },
  });

  return { messageMoveMutate };
};
