import styled from "styled-components";

export const DivActionNav = styled.div`
  display: flex;
  margin-top: 0rem;
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
`;
