import styled from "styled-components";
import { Control, FieldValues } from "react-hook-form";
import SelectField, { SelectFieldOption } from "components/UI/SelectField";
import { SEARCH_SORT } from "constant/search/search";
import { DivSelectField } from "../style/SelectField/DivSelectField";

type SearchSortProps = {
  control: Control<FieldValues, any>;
  sortByName: string;
  sortByDefaultValue: any;
  sortOrderName: string;
  sortOrderDefaultValue: any;
  sortOptions: SelectFieldOption[];
};

const SearchSort = ({
  control,
  sortByName,
  sortByDefaultValue,
  sortOrderName,
  sortOrderDefaultValue,
  sortOptions,
}: SearchSortProps) => {
  return (
    <DivSort>
      <label>Redosled</label>
      <DivSelectField>
        <SelectField
          label=""
          name={sortByName}
          control={control as unknown as Control<FieldValues, any>}
          options={sortOptions}
          defaultValue={sortByDefaultValue}
          onSelect={(val: any) => {}}
        />
      </DivSelectField>

      <DivSelectField>
        <SelectField
          label=""
          name={sortOrderName}
          control={control as unknown as Control<FieldValues, any>}
          options={[
            { value: "", label: "podrazumevano" },
            { value: SEARCH_SORT.ASC.value, label: SEARCH_SORT.ASC.label },
            { value: SEARCH_SORT.DESC.value, label: SEARCH_SORT.DESC.label },
          ]}
          defaultValue={sortOrderDefaultValue}
          onSelect={(val: any) => {}}
        />
      </DivSelectField>
    </DivSort>
  );
};

const DivSort = styled.div`
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  input {
    width: 5rem;
  }
`;

export default SearchSort;
