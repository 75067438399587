import { Editor } from "@tinymce/tinymce-react";
import { ReactRefI } from "type/ref.type";

type TinyMCEEditorProps = {
  initialValue?: string;
  initialHeight?: number;
  editorRef: ReactRefI;
};

const TinyMCEEditor = ({
  initialValue,
  initialHeight,
  editorRef,
}: TinyMCEEditorProps) => {
  return (
    <div>
      <Editor
        apiKey="qpvp98mo5biolpjz1dz7ium280jrrl9u0xuibmdt5sz6fcqd"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        init={{
          height: initialHeight ?? 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </div>
  );
};

export default TinyMCEEditor;
