import { UserResult } from "common/user/dto/UserResult";

export const userIdsInclude = (
  users: UserResult[],
  ids: string[]
): UserResult[] => {
  const result: UserResult[] = users.filter((user: UserResult) =>
    ids.includes(user.id)
  );
  return result;
};

export const userIdsExclude = (
  users: UserResult[],
  ids: string[]
): UserResult[] => {
  const result: UserResult[] = users.filter(
    (user: UserResult) => !ids.includes(user.id)
  );
  return result;
};

export const channelSelectedClassNameGet = (
  channelId: string,
  selectedClass?: string,
  selectedIds?: string[]
): string => {
  const className: string =
    selectedClass && selectedIds && selectedIds.includes(channelId)
      ? selectedClass
      : "";

  return className;
};
