import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMessageAdd } from "hooks/matsend/message/useMessageAdd";
import { QUERY_KEYS } from "constant/react_query";
import MessageForm from "components/common/message/MessageForm/MessageForm";
import { messageFormDefaultValuesGet } from "common/message/message_form_common";
import { defaultValueGet } from "common/parameters_common";
import { messageFileInitialGet } from "../MessageForm/MessageForm.logic";
import { MessageFile } from "common/message/dto/MessageFile";
import { checkFormatSimpleHTML } from "common/message/message_edit_common";

type MessageNewProps = {
  channelId: string;
};

const MessageNew = ({ channelId }: MessageNewProps) => {
  const { handleSubmit, control } = useForm({
    defaultValues: messageFormDefaultValuesGet(),
  });
  const { messageAddMutate } = useMessageAdd({
    invalidateKeys: [QUERY_KEYS.MATSEND_MESSAGE_SEARCH],
    successHandle: (id: any) => {
      console.log("add success", id);
    },
  });
  const [showForm, setShowForm] = useState<boolean>(true);
  useEffect(() => {
    if (!showForm) {
      setShowForm(true);
    }
  }, [showForm]);
  const editorRef = useRef<any>(null);
  const fileEditRef = useRef<any>(
    messageFileInitialGet(
      defaultValueGet(messageFormDefaultValuesGet(), "content", "")
    )
  );
  const fileRemoveRef = useRef<any>([]);

  const onSubmit = async (data: any) => {
    const contentText = data.contentText;
    const editorContent =
      editorRef.current !== null ? editorRef.current.getContent() : "";
    const messageFiles: MessageFile[] =
      fileEditRef.current !== null ? fileEditRef.current : [];
    const content = {
      text: editorContent !== "" ? editorContent : contentText,
      file: messageFiles,
    };
    // const content = {
    //   text:
    //     editorContent !== ""
    //       ? editorContent
    //       : checkFormatSimpleHTML(contentText),
    //   file: messageFiles,
    // };
    const fileRemove = fileRemoveRef.current;

    messageAddMutate({ channelId, content, fileRemove });
    fileEditRef.current = [];
    fileRemoveRef.current = [];
    toast.success("New message added");
    setShowForm(false);
  };

  return (
    <>
      {showForm && (
        <DivMessage>
          <MessageForm
            control={control}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            editorRef={editorRef}
            fileEditRef={fileEditRef}
            fileRemoveRef={fileRemoveRef}
          />
        </DivMessage>
      )}
    </>
  );
};

const DivMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

export default MessageNew;
