export const QUERY_KEYS = {
  AUTH_CURRENT_GET: "AUTH_CURRENT_GET",
  AUTH_LOGIN_POST: "AUTH_LOGIN_POST",
  MATSEND_CHANNEL_GET: "MATSEND_CHANNEL_GET",
  MATSEND_CHANNEL_LIST_GET: "MATSEND_CHANNEL_LIST_GET",
  MATSEND_CHANNEL_SEARCH: "MATSEND_CHANNEL_SEARCH",
  MATSEND_MESSAGE_GET: "MATSEND_MESSAGE_GET",
  MATSEND_MESSAGE_LIST_GET: "MATSEND_MESSAGE_LIST_GET",
  MATSEND_MESSAGE_SEARCH: "MATSEND_MESSAGE_SEARCH",
  MATSEND_MESSAGE_FILE_RETRIEVE: "MATSEND_MESSAGE_FILE_RETRIEVE",
  MATSEND_MESSAGE_FILE_SEARCH: "MATSEND_MESSAGE_FILE_SEARCH",
  MATSEND_USER_GET: "MATSEND_USER_GET",
  MATSEND_USER_LIST_GET: "MATSEND_USER_LIST_GET",
  MATSEND_USER_SEARCH: "MATSEND_USER_SEARCH",
  TEST_CONTENT_GET: "TEST_CONTENT_GET",
};
