import { ChannelResult } from "./dto/ChannelResult";

export const channelFormDefaultValuesGet = (channel?: ChannelResult) => {
  if (channel) {
    return {
      slug: channel.slug,
      accessBy: channel.accessBy,
    };
  } else {
    return {
      slug: "",
      accessBy: [],
    };
  }
};
