import { UserResult } from "common/user/dto/UserResult";
import styled from "styled-components";
import { Divider } from "@mui/material";
import UserLiteSingleNav from "./UserLiteSingleNav";

type UserLiteItemProps = {
  user: UserResult;
  addHandle?: Function;
  removeHandle?: Function;
  removeConfirmMessage?: string;
};

const UserLiteItem = ({
  user,
  addHandle,
  removeHandle,
  removeConfirmMessage,
}: UserLiteItemProps) => {
  return (
    <Div>
      <Divider />
      <Div>
        <div>
          <div>{user.un}</div>
          <div>{user.email} </div>
        </div>
        <UserLiteSingleNav
          user={user}
          addHandle={addHandle}
          removeHandle={removeHandle}
          removeConfirmMessage={removeConfirmMessage}
        />
      </Div>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2rem;
  line-height: 1.25rem;
`;

export default UserLiteItem;
