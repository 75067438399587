import { ChannelResult } from "common/channel/dto/ChannelResult";
import SearchResultList, {
  PageNavigation,
} from "components/common/SearchResultList";
import ChannelItem from "./ChannelItem";
import { useContext, useEffect, useState } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { ChannelSearchParams } from "common/api/matsend/matsend_channel";
import { CHANNEL_PER_PAGE_MAX } from "constant/channel";
import { useChannelSearch } from "hooks/matsend/channel/useChannelSearch";
import {
  channelSearchFieldsGet,
  channelTypeDefaultGet,
} from "common/channel/channel_search";
import ChannelSearchFilters from "components/common/channel/ChannelSearchFilters";
import { WsNotifyContext } from "common/ws/WsNotify";
import { useIsAdmin } from "hooks/auth/auth_current";
import { SEARCH_NAMES } from "constant/search/search";

const ChannelList = () => {
  const isAdmin: boolean = useIsAdmin();
  const [searchParams, setSearchParams] = useState<ChannelSearchParams>({
    limit: CHANNEL_PER_PAGE_MAX,
    skip: 0,
    [SEARCH_NAMES.CHANNEL_TYPE]: channelTypeDefaultGet(),
  });
  const channelListResult = useChannelSearch(searchParams);
  const invalidateQuery = useInvalidateQueryByKeys();
  const wsNotifyContextValue = useContext(WsNotifyContext);

  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([
      QUERY_KEYS.MATSEND_CHANNEL_SEARCH,
    ]);
  }, [searchParams, invalidateQuery, wsNotifyContextValue.notifyTime]);

  let results: ChannelResult[] = channelListResult
    ? channelListResult["items"]
    : [];
  const resTotal: number = channelListResult
    ? channelListResult["resTotal"]
    : 0;
  const pageNavigationHandle = (pageNum: number) => {
    const skip = pageNum * CHANNEL_PER_PAGE_MAX;
    setSearchParams({ ...searchParams, skip });
  };
  const pageNavigation: PageNavigation = {
    resTotal,
    limit: CHANNEL_PER_PAGE_MAX,
    skip: searchParams.skip ?? 0,
    pageNavigationHandle,
  };
  const searchHandle = (formData: any) => {
    const searchVals = channelSearchFieldsGet(formData);

    setSearchParams({
      ...searchParams,
      ...searchVals,
      skip: 0,
    });
  };

  return (
    <div>
      <ChannelSearchFilters searchHandle={searchHandle} />
      <SearchResultList<ChannelResult>
        renderSingle={(channel: ChannelResult) => (
          <ChannelItem channel={channel} key={`channel_${channel.id}`} />
        )}
        results={results}
        pageNavigation={pageNavigation}
      />
    </div>
  );
};

export default ChannelList;
