import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import ChannelForm from "components/common/channel/ChannelForm/ChannelForm";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import ChannelSingleNav from "components/common/channel/ChannelSingleNav";
import { useChannelUpdate } from "hooks/matsend/channel/useChannelUpdate";
import { channelFormDefaultValuesGet } from "common/channel/channel_form_common";
import ChannelAccessEdit from "components/common/channel/ChannelAccessEdit";

type ChannelEditProps = {
  channel: ChannelResult;
};

const ChannelEdit = ({ channel }: ChannelEditProps) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: channelFormDefaultValuesGet(channel),
  });
  const { channelUpdateMutate } = useChannelUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.MATSEND_CHANNEL_SEARCH],
      [QUERY_KEYS.MATSEND_CHANNEL_GET],
    ],
  });

  const onSubmit = async (data: any) => {
    console.log(data);

    const { slug } = data;
    channelUpdateMutate({
      id: channel.id,
      updatePayload: {
        slug,
        parentChannelId: parentIdRef.current,
      },
    });
    toast.success("Channel updated");
  };

  const parentIdRef = useRef<string>(channel.id);

  return (
    <DivChannel>
      <ChannelForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        reset={reset}
        defaultValues={channelFormDefaultValuesGet(channel)}
        parentIdDefault={channel.parentChannelId}
        parentIdRef={parentIdRef}
        channelSelectExcludeIds={[channel.id]}
      />

      <DivNav>
        <ChannelSingleNav channel={channel} hideEditNav={true} />
      </DivNav>

      <ChannelAccessEdit
        channelId={channel.id}
        defaultValue={channel.accessBy}
      />
    </DivChannel>
  );
};

const DivChannel = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default ChannelEdit;
