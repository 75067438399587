import { MessageResult } from "common/message/dto/MessageResult";
import { PageNavigation } from "components/common/SearchResultList";
import { useCallback, useContext, useEffect, useState } from "react";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import { MessageSearchParams } from "common/api/matsend/matsend_message";
import { MESSAGE_PER_PAGE_MAX } from "constant/message";
import { useMessageSearch } from "hooks/matsend/message/useMessageSearch";
import { messageSearchFieldsGet } from "common/message/message_search";
import { WsNotifyContext } from "common/ws/WsNotify";
import { MESSAGE_SEARCH_NAMES } from "constant/search/message_search";
import MessageSearchFilters from "../MessageSearchFilters";
import MessageList from "./MessageList";

type MessageSearchListProps = {
  channelId?: string;
};

const MessageSearchList = ({ channelId }: MessageSearchListProps) => {
  const [searchParams, setSearchParams] = useState<MessageSearchParams>({
    limit: MESSAGE_PER_PAGE_MAX,
    skip: 0,
  });
  const messageListResult = useMessageSearch(searchParams);
  const invalidateQuery = useInvalidateQueryByKeys();
  const queriesInvalidateByKeys = useCallback(
    invalidateQuery.invalidateQueriesByKeys,
    []
  );
  const wsNotifyContextValue = useContext(WsNotifyContext);

  useEffect(() => {
    queriesInvalidateByKeys([QUERY_KEYS.MATSEND_MESSAGE_SEARCH]);
  }, [searchParams, queriesInvalidateByKeys, wsNotifyContextValue.notifyTime]);

  useEffect(() => {
    if (channelId) {
      setSearchParams({
        ...searchParams,
        [MESSAGE_SEARCH_NAMES.CHANNEL_ID]: channelId,
      });
    }
  }, [channelId]);

  let results: MessageResult[] = messageListResult
    ? messageListResult["items"]
    : [];
  const resTotal: number = messageListResult
    ? messageListResult["resTotal"]
    : 0;
  const pageNavigationHandle = (pageNum: number) => {
    const skip = pageNum * MESSAGE_PER_PAGE_MAX;
    setSearchParams({ ...searchParams, skip });
  };
  const pageNavigation: PageNavigation = {
    resTotal,
    limit: MESSAGE_PER_PAGE_MAX,
    skip: searchParams.skip ?? 0,
    pageNavigationHandle,
  };
  const searchHandle = (formData: any) => {
    const searchVals = messageSearchFieldsGet(formData);

    setSearchParams({
      ...searchParams,
      ...searchVals,
      skip: 0,
    });
  };

  return (
    <div>
      <MessageSearchFilters searchHandle={searchHandle} />
      <MessageList messages={results} pageNavigation={pageNavigation} />
    </div>
  );
};

export default MessageSearchList;
