import { useMutation } from "@tanstack/react-query";
import {
  ChannelAddParams,
  channelAdd,
} from "common/api/matsend/matsend_channel";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseChannelAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useChannelAdd = (hookParams?: UseChannelAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: channelAddMutate } = useMutation({
    mutationFn: async (channelAddParams: ChannelAddParams) =>
      await channelAdd(channelAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { channelAddMutate };
};
