import styled from "styled-components";
import { MessageFile } from "common/message/dto/MessageFile";
import DownloadIcon from "@mui/icons-material/Download";
import { fileNameGet } from "common/util/file/file_common";
import { downloadOpenMessageFile } from "common/message/file/message_file";
import { sizeInfoGet } from "common/message/message_common";
import { FILE_DOWNLOAD_OPEN_TYPES } from "constant/file/file_constants";
import MessageFileClickActLink from "../../../MessageFileClickActLink";
import { showMessageFileName } from "./MessageFileReadNav.logic";

type MessageFileReadNavProps = {
  messageFile: MessageFile;
  disableLinkDownload?: boolean;
};

const MessageFileReadNav = ({
  messageFile,
  disableLinkDownload,
}: MessageFileReadNavProps) => {
  const downloadClickHandle = async () => {
    const filePath: string = messageFile.path as string;
    await downloadOpenMessageFile(filePath, FILE_DOWNLOAD_OPEN_TYPES.DOWNLOAD);
  };

  const sizeInfo: string = sizeInfoGet(messageFile.size ?? null);
  const fileName: string = fileNameGet(messageFile.path);

  return showMessageFileName(messageFile) ? (
    <DivNav>
      {!disableLinkDownload && (
        <MessageFileClickActLink messageFilePath={messageFile.path}>
          {fileName}
        </MessageFileClickActLink>
      )}
      {disableLinkDownload && fileName}
      {sizeInfo && <SpanSize>{sizeInfo}</SpanSize>}
      {!disableLinkDownload && (
        <DownloadNav>
          <DownloadIconNav onClick={downloadClickHandle} />
        </DownloadNav>
      )}
    </DivNav>
  ) : null;
};

const DownloadIconNav = styled(DownloadIcon)`
  margin-right: 1rem;
  cursor: pointer;
`;

const SpanSize = styled.span`
  margin-left: 0.3rem;
  font-size: 0.9rem;
`;

const DivNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const DownloadNav = styled.span`
  margin-left: 1rem;
`;

export default MessageFileReadNav;
