import { ChannelResult } from "common/channel/dto/ChannelResult";
import styled from "styled-components";
import ChannelSingleNav from "components/common/channel/ChannelSingleNav";
import { Divider } from "@mui/material";
import { channelMessagesLinkSingle } from "common/channel/channel_link_common";
import NavLinkUndecorated from "components/common/style/link/NavLinkUndecorated";
import ChannelChildListNav from "components/common/channel/ChannelChildListNav";

type ChannelItemProps = {
  channel: ChannelResult;
};

const ChannelItem = ({ channel }: ChannelItemProps) => {
  console.log("channel", channel);

  return (
    <Div>
      <Divider />
      <Div>
        <NavLinkUndecorated to={channelMessagesLinkSingle(channel.id)}>
          <div>{channel.slug}</div>
        </NavLinkUndecorated>
        <ChannelSingleNav channel={channel} />
        <ChannelChildListNav channel={channel} />
      </Div>
    </Div>
  );
};

const Div = styled.div`
  margin-top: 2rem;
  line-height: 1.25rem;
`;

export default ChannelItem;
