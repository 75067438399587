import React, { useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { QUERY_KEYS } from "constant/react_query";
import { MessageResult } from "common/message/dto/MessageResult";
import { useMessageUpdate } from "hooks/matsend/message/useMessageUpdate";
import { messageFormDefaultValuesGet } from "common/message/message_form_common";
import MessageForm from "../../MessageForm";
import MessageSingleNav from "../../MessageSingleNav";
import { messageUpdatePayloadGet } from "./MessageEdit.logic";

type MessageEditProps = {
  message: MessageResult;
  readModeSet: Function;
};

const MessageEdit = ({ message, readModeSet }: MessageEditProps) => {
  const { handleSubmit, control } = useForm({
    defaultValues: messageFormDefaultValuesGet(message),
  });

  const { messageUpdateMutate } = useMessageUpdate({
    invalidateKeysArray: [
      [QUERY_KEYS.MATSEND_MESSAGE_SEARCH],
      [QUERY_KEYS.MATSEND_MESSAGE_GET],
    ],
    onSuccessAdditionalAction: readModeSet,
  });
  const editorRef = useRef<any>(null);
  const fileEditRef = useRef<any>(message.content.file ?? []);
  const fileRemoveRef = useRef<any>([]);

  const onSubmit = async (data: any) => {
    const updatePayload = messageUpdatePayloadGet(
      message,
      data,
      editorRef,
      fileEditRef,
      fileRemoveRef
    );

    messageUpdateMutate({
      id: message.id,
      updatePayload,
    });
    toast.success("Message updated");
  };

  return (
    <DivMessage>
      <MessageForm
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        defaultValues={messageFormDefaultValuesGet(message)}
        editCancelClickHandle={readModeSet}
        editorRef={editorRef}
        fileEditRef={fileEditRef}
        fileRemoveRef={fileRemoveRef}
      />
      <DivNav>
        <MessageSingleNav message={message} hideEditNav={true} />
      </DivNav>
    </DivMessage>
  );
};

const DivMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  margin-top: 1rem;
`;

export default MessageEdit;
