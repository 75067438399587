import { downloadOpenMessageFile } from "common/message/file/message_file";
import { FILE_DOWNLOAD_OPEN_TYPES } from "constant/file/file_constants";
import styled from "styled-components";

type MessageFileClickActLinkProps = {
  messageFilePath: string;
  children?: React.ReactNode;
};

const MessageFileClickActLink = ({
  messageFilePath,
  children,
}: MessageFileClickActLinkProps) => {
  const navClickHandle = async () => {
    await downloadOpenMessageFile(
      messageFilePath,
      FILE_DOWNLOAD_OPEN_TYPES.OPEN
    );
  };

  const navLabelContent = children ?? <>open</>;

  return <Span onClick={navClickHandle}>{navLabelContent}</Span>;
};

const Span = styled.span`
  margin-left: 0rem;
  cursor: pointer;
`;

export default MessageFileClickActLink;
