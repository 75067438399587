import { obDefaultValGet } from "common/common";
import { sortFieldsGet } from "common/search/search_common";
import { CHANNEL_TYPE } from "constant/search/channel_search";
import { SEARCH_NAMES } from "constant/search/search";

export const channelSearchFieldsGet = (searchFieldOb: any = null) => {
  let result = null;
  const channelTypeDefault: string = channelTypeDefaultGet();
  if (searchFieldOb === null) {
    result = {
      [SEARCH_NAMES.SEARCH_TEXT]: "",
      [SEARCH_NAMES.CHANNEL_TYPE]: channelTypeDefault,
      [SEARCH_NAMES.PARENT_CHANNEL_ID]: "",
    };
  } else {
    result = {
      [SEARCH_NAMES.SEARCH_TEXT]: obDefaultValGet(
        searchFieldOb,
        SEARCH_NAMES.SEARCH_TEXT,
        ""
      ),
      [SEARCH_NAMES.CHANNEL_TYPE]: obDefaultValGet(
        searchFieldOb,
        SEARCH_NAMES.CHANNEL_TYPE,
        channelTypeDefault
      ),
      [SEARCH_NAMES.PARENT_CHANNEL_ID]: obDefaultValGet(
        searchFieldOb,
        SEARCH_NAMES.PARENT_CHANNEL_ID,
        ""
      ),
    };
  }

  const sortFields = sortFieldsGet(searchFieldOb);
  result = { ...result, ...sortFields };

  return result;
};

export function channelTypeDefaultGet(): string {
  const channelTypeDefault: string = CHANNEL_TYPE.ALL;

  return channelTypeDefault;
}
