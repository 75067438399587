import { SelectFieldOption } from "components/UI/SelectField";

export const USER_PER_PAGE_MAX = 5;

export const USER_TYPES = {
  ADMIN: { value: "admin", label: "admin" },
  STANDARD: { value: "standard", label: "standard" },
};

export const USER_TYPE_OPTIONS: SelectFieldOption[] = [
  USER_TYPES.STANDARD,
  USER_TYPES.ADMIN,
];
