import styled from "styled-components";
import { ChannelResult } from "common/channel/dto/ChannelResult";
import { QUERY_KEYS } from "constant/react_query";
import { useState } from "react";
import ChannelChildList from "./ChannelChildList/ChannelChildList";

type ChannelChildListNavProps = {
  channel: ChannelResult;
};

const ChannelChildListNav = ({ channel }: ChannelChildListNavProps) => {
  const [showChildList, setShowChildList] = useState<boolean>(false);

  const parentNavClickHandle = async () => {
    console.log("parentNavClickHandle");
    setShowChildList((previousVal: boolean) => !previousVal);
  };

  if (!channel || channel.childCount === 0) {
    return null;
  }

  // return <div>t</div>;

  return (
    <div>
      <DivNav onClick={parentNavClickHandle}>
        channels ({channel.childCount})
      </DivNav>
      {showChildList && (
        <DivChildList>
          <ChannelChildList parentChannelId={channel.id} />
        </DivChildList>
      )}
    </div>
  );
};

const DivNav = styled.div`
  cursor: pointer;
`;

const DivChildList = styled.div`
  margin-top: 0.75rem;
  margin-left: 1rem;
`;

export default ChannelChildListNav;
