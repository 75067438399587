import { apiMatsendUrlGet } from "common/api/api_common";
import { API_APP_NAMES } from "common/api/api_constants";
import { authBearer } from "common/api/auth/auth_common";
import { matsendApiAuth } from "common/api/axios/matsend_axios";
import { fileNameGet } from "common/util/file/file_common";
import { FILE_DOWNLOAD_OPEN_TYPES } from "constant/file/file_constants";

export const retrieveMessageFileBlob = async (filePath: string) => {
  const url: string = apiMatsendUrlGet("message/file/retrieve");

  let fetchOptions: any = messageFileFetchOptions(
    filePath,
    matsendApiAuth.token
  );
  let response = await fetch(url, fetchOptions);

  if (response.status === 401) {
    const bearerResult = await authBearer(API_APP_NAMES.MATSEND);
    matsendApiAuth.token = bearerResult.token;
    fetchOptions = messageFileFetchOptions(filePath, matsendApiAuth.token);
    response = await fetch(url, fetchOptions);
  }

  const blob = await response.blob();

  return blob;
};

const messageFileFetchHeaders = (bearerToken: string): any => {
  const fetchHeaders: any = {
    "Content-Type": "application/json",
  };
  fetchHeaders["Authorization"] = `Bearer: ${bearerToken}`;

  return fetchHeaders;
};

const messageFileFetchOptions = (
  filePath: string,
  bearerToken: string
): any => {
  const fetchHeaders: any = messageFileFetchHeaders(bearerToken);
  const options: any = {
    method: "POST",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: fetchHeaders,
    body: JSON.stringify({
      filePath,
    }),
  };

  return options;
};

export const retrieveMessageFileSrc = async (filePath: string) => {
  const blob = await retrieveMessageFileBlob(filePath);

  const reader = new FileReader();

  const imageSrc = await new Promise((resolve, reject) => {
    // Add a listener to handle successful reading of the blob
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });

    // Start reading the content of the blob
    // The result should be a base64 data URL
    reader.readAsDataURL(blob);
  });

  return imageSrc;
};

export const downloadOpenMessageFile = async (
  filePath: string,
  handleType: string = FILE_DOWNLOAD_OPEN_TYPES.OPEN
) => {
  const blob = await retrieveMessageFileBlob(filePath);
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;

  if (handleType === FILE_DOWNLOAD_OPEN_TYPES.DOWNLOAD) {
    a.download = fileNameGet(filePath);
  } else {
    a.target = "_blank";
  }

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("click", clickHandler);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);

  a.click();
};
