import { fileExtensionGet } from "common/util/file/file_common";
import { MessageFile } from "../dto/MessageFile";
import { MESSAGE_IMAGE_SHOW_EXTENSION } from "constant/message";

export function messageFilePathImageCheck(filePath: string): boolean {
  const ext: string = fileExtensionGet(filePath);
  const isImage: boolean = MESSAGE_IMAGE_SHOW_EXTENSION.includes(ext);

  return isImage;
}

export function messageFileIsImage(messageFile: MessageFile): boolean {
  const isImage: boolean = messageFilePathImageCheck(messageFile.path);

  return isImage;
}
